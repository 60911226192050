<template>
    <div class="footer d-flex flex-wrap flex-md-nowrap">
        <router-link to="/kontakt">
            <p class="headline-text-shadow">Kontakt</p>
        </router-link>
        <router-link to="/impressum">
            <p class="headline-text-shadow">Impressum</p>
        </router-link>
        <router-link to="/datenschutz">
            <p class="headline-text-shadow">Datenschutz</p>
        </router-link>
        <p class="headline-text-shadow">© 2022 - Amon Wondra</p>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style lang="scss">
    .footer {
        width: 100%;
        justify-content: space-evenly;
        margin-top: 40px;
        bottom: 0;
        background-color: var(--bg-secondary);
        border-radius: 8px;
        box-shadow: inset 0px 0px 15px #000000;
        p, a {
            text-decoration: none;
            color: white;
            font-size: 24px;
        }

        p {
            height: 100%;
            margin: 16px 10px;
            padding: 10px 0;
        }
    }
</style>