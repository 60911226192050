<template>
    <div class="sidebar">
        <div class="nav-button d-flex justify-end">
            <router-link class="nav-arrow d-flex" to="/">
                <ArrowRightIcon title="Startseite" :size=40 class="nav-arrow-svg d-flex align-center" fill-color="white"/>
            </router-link>
        </div>
    </div>
</template>

<script>
import ArrowRightIcon from 'vue-material-design-icons/ArrowRight.vue'
export default {
    name: "NavButton",
    components: {
        ArrowRightIcon
    }
}
</script>

<style lang="scss">
.sidebar {
    position: fixed;
    z-index: 100;
    margin-left: -12px;
    .nav-button {
        width: 50px;
        height: 100px; /* as the half of the width */
        background-color: var(--bg-secondary);
        border-top-right-radius: 110px; /* 100px of height + 10px of border */
        border-bottom-right-radius: 110px; /* 100px of height + 10px of border */
        border: 10px solid gray;
        border-left: 0;
        position: absolute;
        transition: all .5s var(--transition-timing-function);
        right: auto;
        left: 0;
        top: 45vh;
        margin: 0;
        padding-right: 5px;
        .nav-arrow{
            height: 100%;
            &-svg {
                transition: all .5s var(--transition-timing-function);
                transform: rotate(180deg);
                &:hover {
                    transform: scale(1.1) rotate(180deg)
                }
            }
        }
    }
}
</style>