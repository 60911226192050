// i18n.js

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  // Hier kommen deine Übersetzungen
  en: {
    presskit1: "Amonfire, also known as Amon Wondra, is an emerging Drum and Bass DJ & Producer from the heart of Lübeck. His music selection ranges from Liquid/Power Liquid Drum & Bass to Neurofunk and Ragga Jungle tracks. The creative mix of vocal and melodious songs between 170 - 178 BPM not only makes hearts beat faster on the dance floor but also captivates people from different genres in the enchantment of the dancing crowd.",
    presskit2: "In spring 2022, his path led him to the 'unklar' - a small bar in the Lübeck student hotspot, Clemensstraße, as part of the <a href='https://www.kulturfunke.de/event/unklarbar-newcomer-night-mit-amonfire-dnb-1414' class='in-text-link'>Kulturfunke Newcomer Night</a>. With his first gig, he laid the foundation for his DJ career, filling one dance floor after another. At the Vault Rave of the Hanse Culture Festival's Aftershow Party and Lübeck's first major Drum & Bass event, 'M.A.U.S. 01' where amonfire performed with various DJs such as Steve Phatvibe aka DJ Bolt and other local celebrities, he created vibrating floors and radiant faces. The big dream was to play at a festival, and the opportunity did not take long to come. In August 2022, he had the chance to perform at the Glo Lounge at the Pangea Festival. The response was so great that the entire weekend in the lounge was filled with Drum & Bass beats.",
    presskit3: "Amonfire produced his first instrumental in June 2021 at his desk in his shared apartment, which, almost half a year later, became a complete song with two other artists and found its way onto major streaming platforms. Following this, in November 2022, he released his first Drum & Bass track through <a href='https://www.utm-records.de/' rel='noreferrer noopener' target='_blank'>UTM-Records.</a>",
    // Additional translations...
  },  
  de: {
    presskit1: "Amonfire aka. Amon Wondra ist ein aufstrebender Drum and Bass DJ & Produzent aus demHerzenLübecks. Seine Musikauswahl reicht von Liquid/Power Liquid Drum & Bass bis hin zuNeurofunkund Ragga Jungle Tracks. Der kreative Mix aus den vocal- und melodienreichen Songszwischen 170 - 178 BPM lassen nicht nur die Herzen auf der Tanzfläche schnellerschlagen, sondern ziehen genrefremde Menschen in den Bann der tanzenden Crowd.",
    presskit2: 'Im Frühjahr 2022 führte ihn sein Weg im Rahmen der<a href="https://www.kulturfunke.de/event/unklarbar-newcomer-night-mit-amonfire-dnb-1414"class="in-text-link">Kulturfunke Newcomer Night</a> ins "unklar" - einerkleinen Bar im lübeckerStudenten-Hotspot, der Clemensstraße. Mit seinem ersten Gig legte er dort den Grundsteinseiner DJ Karriere, wodurch er einen Dancefloor nach dem nächsten füllte. Bei demGewölberave der Aftershow Party vom Hansekulturfestival sowie der ersten großen Drum &BassVeranstaltung in Lübeck "M.A.U.S. 01", wo amonfire mit diversen DJs wie Steve Phatvibeaka.DJ Bolt und weiteren lokalen Bekanntheiten auflegte, sorgte er für vibrierende Böden undstrahlende Gesichter. Der große Traum war es fortan auf einem Festival spielen zu dürfenundauch die Anfrage sollte nicht lange auf sich warten lassen. Im August 2022 bekam er dieMöglichkeit in der Glo Lounge auf dem Pangea Festival aufzulegen. Die Resonanz war sogroß,dass das gesamte Wochenende in der Lounge zu Drum & Bass getanzt wurde.',
    presskit3: 'Sein erstes Instrumental produzierte amonfire im Juni 2021 an seinem Schreibtisch inseinerWG, welches knapp ein halbes Jahr später als vollständiger Song mit zwei weiterenArtistsseinen Weg auf die gängigen Streaming-Plattformen fand.Darauf folgt im November 2022 der Release seines ersten Drum & Bass Tracks über <ahref="https://www.utm-records.de/"rel="noreferrer noopener" target="_blank">UTM-Records.</a>',
    // Weitere Übersetzungen...
  },
  // Weitere Sprachen hier hinzufügen
};

const i18n = new VueI18n({
  locale: 'de', // Standardmäßig auf Englisch setzen
  fallbackLocale: 'de',
  messages,
});

export default i18n;
