<template>
    <v-container class="container--fluid text--white contact page background-image">
        <v-layout>
            <v-col class="page-container col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 d-flex flex-column container-shadow">
                <NavButton/>
                <v-col class="d-flex flex-column hide-scrollbar px-md-10">
                    <v-row>
                        <v-col class="d-flex flex-column hide-scrollbar px-md-10">
                            <TextAnimation upper-text="Kontakt" lower-text="Anfragen & Booking"/>
                            <div class="pt-10">
                                <div class="page-content d-flex flex-column flex-md-row overflow-visible">
                                    <div class="page-content-text d-flex flex-column col-12 col-md-6 container-shadow">
                                        <p>Für sämtliche Anfragen melde
                                            dich gerne über die klassischen
                                            Kanäle auf der
                                            <router-link to="/" class="in-text-link">Startseite</router-link>.
                                            Solltest du keine dieser Plattformen nutzen, erreichst du mich auch auf
                                            klassischem wege unter:
                                        </p>
                                        <h3>Kontakt</h3>
                                        <p>Telefon: &#48;&#49;&#55;&#56;&#56;&#48;&#49;&#51;&#53;&#50;&#57;<br/>
                                            E-Mail: &#98;&#111;&#111;&#107;&#105;&#110;&#103;&#64;&#97;&#109;&#111;&#110;&#119;&#111;&#110;&#100;&#114;&#97;&#46;&#100;&#101;
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <Footer/>
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
import TextAnimation from "../components/TextAnimation";
import Footer from "../components/Footer";
/*import BookmarkMusic from 'vue-material-design-icons/BookmarkMusic.vue'
import CodeGreaterThan from 'vue-material-design-icons/CodeGreaterThan.vue'
import Motorbike from 'vue-material-design-icons/Motorbike.vue'
import FileVideo from 'vue-material-design-icons/FileVideo.vue'*/
import NavButton from "@/components/NavButton";

export default {
    name: "Kontakt",
    components: {
        NavButton,
        TextAnimation,
/*        BookmarkMusic,
        CodeGreaterThan,
        FileVideo,
        Motorbike,*/
        Footer
    },
    methods: {}
}
</script>
<style lang="scss" scoped>
.contact {
    height: 100%;
}
.icon {

}
.motorbike-icon svg {
    color: var(--bg-primary);
    background-color: white;
    border-radius: 10px;
    transform: scale(.76);
    &:hover {
        filter: invert(.9);
    }
}
.file-video-icon svg {
    transform: scale(.92);
}
</style>