<template>
    <v-container class="container--fluid text--white impressum page background-image">
        <v-layout>
            <v-col
                class="page-container col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 d-flex flex-column container-shadow">
                <NavButton/>
                <v-col class="d-flex flex-column hide-scrollbar px-md-10">
                    <v-row>
                        <v-col class="d-flex flex-column hide-scrollbar px-md-10">
                            <TextAnimation upper-text="Impressum & Datenschutz" lower-text="Angaben gemäß §5 TMG"/>
                            <div class="pt-10">
                                <div class="page-content d-flex flex-row overflow-visible">
                                    <div class="page-content-text d-flex flex-column col-12 container-shadow">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    color="primary"
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="resetCookies()"
                                                >
                                                    Cookie Einstellungen zurücksetzen
                                                </v-btn>
                                            </template>
                                            <span>Dies löscht alle Cookies dieser Seite</span>
                                        </v-tooltip>
                                        <h1 class="adsimple-312237450">Datenschutzerklärung</h1>
                                        <h2>Inhaltsverzeichnis</h2>
                                        <ul>
                                            <li>
                                                <a href="#einleitung-ueberblick">Einleitung und Überblick</a>
                                            </li>
                                            <li>
                                                <a href="#anwendungsbereich">Anwendungsbereich</a>
                                            </li>
                                            <li>
                                                <a href="#rechtsgrundlagen">Rechtsgrundlagen</a>
                                            </li>
                                            <li>
                                                <a href="#kontaktdaten-verantwortliche">Kontaktdaten des
                                                    Verantwortlichen</a>
                                            </li>
                                            <li>
                                                <a href="#speicherdauer">Speicherdauer</a>
                                            </li>
                                            <li>
                                                <a href="#rechte-dsgvo">Rechte laut Datenschutz-Grundverordnung</a>
                                            </li>
                                            <li>
                                                <a href="#datenuebertragung-drittlaender">Datenübertragung in
                                                    Drittländer</a>
                                            </li>
                                            <li>
                                                <a href="#sicherheit-datenverarbeitung">Sicherheit der
                                                    Datenverarbeitung</a>
                                            </li>
                                            <li>
                                                <a href="#kommunikation">Kommunikation</a>
                                            </li>
                                            <li>
                                                <a href="#cookies">Cookies</a>
                                            </li>
                                            <li>
                                                <a href="#webhosting-einleitung">Webhosting Einleitung</a>
                                            </li>
                                            <li>
                                                <a href="#messenger-kommunikation-einleitung">Messenger &amp;
                                                    Kommunikation Einleitung</a>
                                            </li>
                                            <li>
                                                <a href="#social-media-einleitung">Social Media Einleitung</a>
                                            </li>
                                            <li>
                                                <a href="#audio-video-einleitung">Audio &amp; Video Einleitung</a>
                                            </li>
                                        </ul>
                                        <h2 id="einleitung-ueberblick" class="adsimple-312237450">Einleitung und
                                            Überblick</h2>
                                        <p>Wir haben diese Datenschutzerklärung (Fassung 19.10.2022-312237450) verfasst,
                                            um Ihnen gemäß der Vorgaben der <a class="adsimple-312237450"
                                                                               href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312237450#d1e2269-1-1"
                                                                               target="_blank" rel="noopener">Datenschutz-Grundverordnung
                                                (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären,
                                            welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211;
                                            und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211;
                                            verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen
                                            Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu
                                            verstehen.<br/>
                                            <strong class="adsimple-312237450">Kurz gesagt:</strong> Wir informieren Sie
                                            umfassend über Daten, die wir über Sie verarbeiten.</p>
                                        <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden
                                            juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die
                                            wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit
                                            es der Transparenz förderlich ist, werden technische <strong
                                                class="adsimple-312237450">Begriffe leserfreundlich erklärt</strong>,
                                            Links zu weiterführenden Informationen geboten und <strong
                                                class="adsimple-312237450">Grafiken</strong> zum Einsatz gebracht. Wir
                                            informieren damit in klarer und einfacher Sprache, dass wir im Rahmen
                                            unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten,
                                            wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher
                                            nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische
                                            Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um
                                            Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen
                                            interessant und informativ und vielleicht ist die eine oder andere
                                            Information dabei, die Sie noch nicht kannten.<br/>
                                            Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw.
                                            im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen
                                            Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen.
                                            Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
                                        <h2 id="anwendungsbereich" class="adsimple-312237450">Anwendungsbereich</h2>
                                        <p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten
                                            personenbezogenen Daten und für alle personenbezogenen Daten, die von uns
                                            beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen
                                            Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum
                                            Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die
                                            Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere
                                            Dienstleistungen und Produkte anbieten und abrechnen können, sei es online
                                            oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
                                        <ul class="adsimple-312237450">
                                            <li class="adsimple-312237450">alle Onlineauftritte (Websites, Onlineshops),
                                                die wir betreiben
                                            </li>
                                            <li class="adsimple-312237450">Social Media Auftritte und
                                                E-Mail-Kommunikation
                                            </li>
                                            <li class="adsimple-312237450">mobile Apps für Smartphones und andere
                                                Geräte
                                            </li>
                                        </ul>
                                        <p>
                                            <strong class="adsimple-312237450">Kurz gesagt:</strong> Die
                                            Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten
                                            im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden.
                                            Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen
                                            eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>
                                        <h2 id="rechtsgrundlagen" class="adsimple-312237450">Rechtsgrundlagen</h2>
                                        <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente
                                            Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den
                                            Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen,
                                            personenbezogene Daten zu verarbeiten.<br/>
                                            Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679
                                            DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese
                                            Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf
                                            EUR-Lex, dem Zugang zum EU-Recht, unter <a class="adsimple-312237450"
                                                                                       href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679</a>
                                            nachlesen.</p>
                                        <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden
                                            Bedingungen zutrifft:</p>
                                        <ol>
                                            <li class="adsimple-312237450">
                                                <strong class="adsimple-312237450">Einwilligung</strong> (Artikel 6
                                                Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten
                                                zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die
                                                Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.
                                            </li>
                                            <li class="adsimple-312237450">
                                                <strong class="adsimple-312237450">Vertrag</strong> (Artikel 6 Absatz 1
                                                lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit
                                                Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel
                                                einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab
                                                personenbezogene Informationen.
                                            </li>
                                            <li class="adsimple-312237450">
                                                <strong class="adsimple-312237450">Rechtliche Verpflichtung</strong>
                                                (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen
                                                Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind
                                                wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben.
                                                Diese enthalten in der Regel personenbezogene Daten.
                                            </li>
                                            <li class="adsimple-312237450">
                                                <strong class="adsimple-312237450">Berechtigte Interessen</strong>
                                                (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die
                                                Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung
                                                personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten
                                                verarbeiten, um unsere Website sicher und wirtschaftlich effizient
                                                betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes
                                                Interesse.
                                            </li>
                                        </ol>
                                        <p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen
                                            Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger
                                            Interessen treten bei uns in der Regel nicht auf. Soweit eine solche
                                            Rechtsgrundlage doch einschlägig sein sollte, wird diese an der
                                            entsprechenden Stelle ausgewiesen.</p>
                                        <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
                                        <ul class="adsimple-312237450">
                                            <li class="adsimple-312237450">In <strong class="adsimple-312237450">Österreich</strong>
                                                ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der
                                                Verarbeitung personenbezogener Daten (<strong
                                                    class="adsimple-312237450">Datenschutzgesetz</strong>), kurz <strong
                                                    class="adsimple-312237450">DSG</strong>.
                                            </li>
                                            <li class="adsimple-312237450">In <strong class="adsimple-312237450">Deutschland</strong>
                                                gilt das <strong
                                                    class="adsimple-312237450">Bundesdatenschutzgesetz</strong>,
                                                kurz<strong class="adsimple-312237450"> BDSG</strong>.
                                            </li>
                                        </ul>
                                        <p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen,
                                            informieren wir Sie in den folgenden Abschnitten darüber.</p>
                                        <h2 id="kontaktdaten-verantwortliche" class="adsimple-312237450">Kontaktdaten
                                            des Verantwortlichen</h2>
                                        <p>Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener
                                            Daten haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen
                                            Person bzw. Stelle:<br/>
                                            <span class="adsimple-312237450" style="font-weight: 400">Amon Wondra<br/>
Schwartauer Allee 5a<br/>
23554 Lübeck</span>
                                        </p>
                                        <p>E-Mail: <a href="mailto:info@amonfire.de">info@amonfire.de</a>
                                            <br/>
                                            Telefon: <a href="tel:+49 178 80 13 529">+49 178 80 13 529</a>
                                            <br/>
                                            Impressum: <a href="https://www.amonfire.de/impressum/">https://www.amonfire.de/impressum/</a>
                                        </p>
                                        <h2 id="speicherdauer" class="adsimple-312237450">Speicherdauer</h2>
                                        <p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die
                                            Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig
                                            ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir
                                            personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung
                                            nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu
                                            verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu
                                            speichern, zum Beispiel zu Zwecken der Buchführung.</p>
                                        <p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur
                                            Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und
                                            soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
                                        <p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie
                                            weiter unten, sofern wir weitere Informationen dazu haben.</p>
                                        <h2 id="rechte-dsgvo" class="adsimple-312237450">Rechte laut
                                            Datenschutz-Grundverordnung</h2>
                                        <p>Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die
                                            Ihnen zustehen, damit es zu einer fairen und transparenten Verarbeitung von
                                            Daten kommt:</p>
                                        <ul class="adsimple-312237450">
                                            <li class="adsimple-312237450">Sie haben laut Artikel 15 DSGVO ein
                                                Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das
                                                zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und
                                                die folgenden Informationen zu erfahren:
                                                <ul class="adsimple-312237450">
                                                    <li class="adsimple-312237450">zu welchem Zweck wir die Verarbeitung
                                                        durchführen;
                                                    </li>
                                                    <li class="adsimple-312237450">die Kategorien, also die Arten von
                                                        Daten, die verarbeitet werden;
                                                    </li>
                                                    <li class="adsimple-312237450">wer diese Daten erhält und wenn die
                                                        Daten an Drittländer übermittelt werden, wie die Sicherheit
                                                        garantiert werden kann;
                                                    </li>
                                                    <li class="adsimple-312237450">wie lange die Daten gespeichert
                                                        werden;
                                                    </li>
                                                    <li class="adsimple-312237450">das Bestehen des Rechts auf
                                                        Berichtigung, Löschung oder Einschränkung der Verarbeitung und
                                                        dem Widerspruchsrecht gegen die Verarbeitung;
                                                    </li>
                                                    <li class="adsimple-312237450">dass Sie sich bei einer
                                                        Aufsichtsbehörde beschweren können (Links zu diesen Behörden
                                                        finden Sie weiter unten);
                                                    </li>
                                                    <li class="adsimple-312237450">die Herkunft der Daten, wenn wir sie
                                                        nicht bei Ihnen erhoben haben;
                                                    </li>
                                                    <li class="adsimple-312237450">ob Profiling durchgeführt wird, ob
                                                        also Daten automatisch ausgewertet werden, um zu einem
                                                        persönlichen Profil von Ihnen zu gelangen.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="adsimple-312237450">Sie haben laut Artikel 16 DSGVO ein Recht auf
                                                Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen
                                                müssen, falls Sie Fehler finden.
                                            </li>
                                            <li class="adsimple-312237450">Sie haben laut Artikel 17 DSGVO das Recht auf
                                                Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie
                                                die Löschung Ihrer Daten verlangen dürfen.
                                            </li>
                                            <li class="adsimple-312237450">Sie haben laut Artikel 18 DSGVO das Recht auf
                                                Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur
                                                mehr speichern dürfen aber nicht weiter verwenden.
                                            </li>
                                            <li class="adsimple-312237450">Sie haben laut Artikel 20 DSGVO das Recht auf
                                                Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre
                                                Daten in einem gängigen Format zur Verfügung stellen.
                                            </li>
                                            <li class="adsimple-312237450">Sie haben laut Artikel 21 DSGVO ein
                                                Widerspruchsrecht, welches nach Durchsetzung eine Änderung der
                                                Verarbeitung mit sich bringt.
                                                <ul class="adsimple-312237450">
                                                    <li class="adsimple-312237450">Wenn die Verarbeitung Ihrer Daten auf
                                                        Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung
                                                        öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes
                                                        Interesse) basiert, können Sie gegen die Verarbeitung
                                                        Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob
                                                        wir diesem Widerspruch rechtlich nachkommen können.
                                                    </li>
                                                    <li class="adsimple-312237450">Werden Daten verwendet, um
                                                        Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art
                                                        der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten
                                                        danach nicht mehr für Direktmarketing verwenden.
                                                    </li>
                                                    <li class="adsimple-312237450">Werden Daten verwendet, um Profiling
                                                        zu betreiben, können Sie jederzeit gegen diese Art der
                                                        Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach
                                                        nicht mehr für Profiling verwenden.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="adsimple-312237450">Sie haben laut Artikel 22 DSGVO unter
                                                Umständen das Recht, nicht einer ausschließlich auf einer
                                                automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden
                                                Entscheidung unterworfen zu werden.
                                            </li>
                                            <li class="adsimple-312237450">Sie haben laut Artikel 77 DSGVO das Recht auf
                                                Beschwerde. Das heißt, Sie können sich jederzeit bei der
                                                Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die
                                                Datenverarbeitung von personenbezogenen Daten gegen die DSGVO verstößt.
                                            </li>
                                        </ul>
                                        <p>
                                            <strong class="adsimple-312237450">Kurz gesagt:</strong> Sie haben Rechte
                                            &#8211; zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns
                                            zu kontaktieren!</p>
                                        <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
                                            Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in
                                            sonst einer Weise verletzt worden sind, können Sie sich bei der
                                            Aufsichtsbehörde beschweren. Diese ist für Österreich die
                                            Datenschutzbehörde, deren Website Sie unter <a class="adsimple-312237450"
                                                                                           href="https://www.dsb.gv.at/?tid=312237450"
                                                                                           target="_blank"
                                                                                           rel="noopener">https://www.dsb.gv.at/</a>
                                            finden. In Deutschland gibt es für jedes Bundesland einen
                                            Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a
                                                class="adsimple-312237450"
                                                href="https://www.bfdi.bund.de/DE/Home/home_node.html" target="_blank"
                                                rel="noopener">Bundesbeauftragte für den Datenschutz und die
                                                Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die
                                            folgende lokale Datenschutzbehörde zuständig:</p>
                                        <h2 id="schleswig-holstein-datenschutzbehoerde" class="adsimple-312237450">
                                            Schleswig-Holstein Datenschutzbehörde</h2>
                                        <p>
                                            <strong class="adsimple-312237450">Landesbeauftragte für
                                                Datenschutz:</strong> Marit Hansen<br/>
                                            <strong class="adsimple-312237450">Adresse: </strong>Holstenstraße 98, 24103
                                            Kiel<br/>
                                            <strong class="adsimple-312237450">Telefonnr.:</strong> 04 31/988-12 00<br/>
                                            <strong class="adsimple-312237450">E-Mail-Adresse:</strong>
                                            mail@datenschutzzentrum.de<br/>
                                            <strong class="adsimple-312237450">Website: </strong>
                                            <a class="adsimple-312237450"
                                               href="https://www.datenschutzzentrum.de/?tid=312237450" target="_blank"
                                               rel="noopener">https://www.datenschutzzentrum.de/</a>
                                        </p>
                                        <h2 id="datenuebertragung-drittlaender" class="adsimple-312237450">
                                            Datenübertragung in Drittländer</h2>
                                        <p>Wir übertragen oder verarbeiten Daten nur dann in Länder außerhalb der EU
                                            (Drittländer), wenn Sie dieser Verarbeitung zustimmen, dies gesetzlich
                                            vorgeschrieben ist oder vertraglich notwendig und in jedem Fall nur soweit
                                            dies generell erlaubt ist. Ihre Zustimmung ist in den meisten Fällen der
                                            wichtigste Grund, dass wir Daten in Drittländern verarbeiten lassen. Die
                                            Verarbeitung personenbezogener Daten in Drittländern wie den USA, wo viele
                                            Softwarehersteller Dienstleistungen anbieten und Ihre Serverstandorte haben,
                                            kann bedeuten, dass personenbezogene Daten auf unerwartete Weise verarbeitet
                                            und gespeichert werden.</p>
                                        <p>Wir weisen ausdrücklich darauf hin, dass nach Meinung des Europäischen
                                            Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in
                                            die USA besteht. Die Datenverarbeitung durch US-Dienste (wie beispielsweise
                                            Google Analytics) kann dazu führen, dass gegebenenfalls Daten nicht
                                            anonymisiert verarbeitet und gespeichert werden. Ferner können
                                            gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne
                                            Daten nehmen. Zudem kann es vorkommen, dass erhobene Daten mit Daten aus
                                            anderen Diensten desselben Anbieters, sofern Sie ein entsprechendes
                                            Nutzerkonto haben, verknüpft werden. Nach Möglichkeit versuchen wir
                                            Serverstandorte innerhalb der EU zu nutzen, sofern das angeboten wird.</p>
                                        <p>Wir informieren Sie an den passenden Stellen dieser Datenschutzerklärung
                                            genauer über Datenübertragung in Drittländer, sofern diese zutrifft.</p>
                                        <h2 id="sicherheit-datenverarbeitung" class="adsimple-312237450">Sicherheit der
                                            Datenverarbeitung</h2>
                                        <p>Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch
                                            organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist, verschlüsseln
                                            oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im
                                            Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren
                                            Daten auf persönliche Informationen schließen können.</p>
                                        <p>Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch Technikgestaltung und
                                            durch datenschutzfreundliche Voreinstellungen&#8221; und meint damit, dass
                                            man sowohl bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang
                                            zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt.
                                            Im Folgenden gehen wir, falls erforderlich, noch auf konkrete Maßnahmen
                                            ein.</p>
                                        <h2 id="tls-verschluesselung-https" class="adsimple-312237450">
                                            TLS-Verschlüsselung mit https</h2>
                                        <p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir
                                            verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres
                                            Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet zu
                                            übertragen.<br/>
                                            Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser
                                            zu unserem Webserver abgesichert ist &#8211; niemand kann &#8220;mithören&#8221;.
                                        </p>
                                        <p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen
                                            den Datenschutz durch Technikgestaltung (<a class="adsimple-312237450"
                                                                                        href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312237450"
                                                                                        target="_blank" rel="noopener">Artikel
                                                25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer
                                            Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im
                                            Internet, können wir den Schutz vertraulicher Daten sicherstellen.<br/>
                                            Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am
                                            kleinen Schlosssymbol <img role="img"
                                                                       src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg"
                                                                       width="17" height="18"/> links oben im Browser,
                                            links von der Internetadresse (z. B. beispielseite.de) und der Verwendung
                                            des Schemas https (anstatt http) als Teil unserer Internetadresse.<br/>
                                            Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die
                                            Google Suche nach &#8220;Hypertext Transfer Protocol Secure wiki&#8221; um
                                            gute Links zu weiterführenden Informationen zu erhalten.</p>
                                        <h2 id="kommunikation" class="adsimple-312237450">Kommunikation</h2>
                                        <table border="1" cellpadding="15">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong class="adsimple-312237450">Kommunikation
                                                        Zusammenfassung</strong>
                                                    <br/>
                                                    &#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder
                                                    Online-Formular kommunizieren<br/>
                                                    &#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name,
                                                    E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden
                                                    Sie bei der jeweils eingesetzten Kontaktart<br/>
                                                    &#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden,
                                                    Geschäftspartnern usw.<br/>
                                                    &#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der
                                                    gesetzlichen Vorschriften<br/>
                                                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                                                    (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1
                                                    lit. f DSGVO (Berechtigte Interessen)
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder
                                            Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener
                                            Daten kommen.</p>
                                        <p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit
                                            zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während eben
                                            solange gespeichert bzw. solange es das Gesetz vorschreibt.</p>
                                        <h3 class="adsimple-312237450">Betroffene Personen</h3>
                                        <p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit
                                            gestellten Kommunikationswege den Kontakt zu uns suchen.</p>
                                        <h3 class="adsimple-312237450">Telefon</h3>
                                        <p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und
                                            beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert.
                                            Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail
                                            versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden
                                            gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben
                                            erlauben.</p>
                                        <h3 class="adsimple-312237450">E-Mail</h3>
                                        <p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf
                                            dem jeweiligen Endgerät (Computer, Laptop, Smartphone,&#8230;) gespeichert
                                            und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten
                                            werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche
                                            Vorgaben erlauben.</p>
                                        <h3 class="adsimple-312237450">Online Formulare</h3>
                                        <p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf
                                            unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von
                                            uns weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall
                                            beendet wurde und es gesetzliche Vorgaben erlauben.</p>
                                        <h3 class="adsimple-312237450">Rechtsgrundlagen</h3>
                                        <p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
                                        <ul class="adsimple-312237450">
                                            <li class="adsimple-312237450">Art. 6 Abs. 1 lit. a DSGVO (Einwilligung):
                                                Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für
                                                den Geschäftsfall betreffende Zwecke zu verwenden;
                                            </li>
                                            <li class="adsimple-312237450">Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es
                                                besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen
                                                oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir
                                                müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die
                                                Vorbereitung eines Angebots, verarbeiten;
                                            </li>
                                            <li class="adsimple-312237450">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
                                                Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation
                                                in einem professionellen Rahmen betreiben. Dazu sind gewisse technische
                                                Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und
                                                Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben
                                                zu können.
                                            </li>
                                        </ul>
                                        <h2 id="cookies" class="adsimple-312237450">Cookies</h2>
                                        <table border="1" cellpadding="15">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong class="adsimple-312237450">Cookies Zusammenfassung</strong>
                                                    <br/>
                                                    &#x1f465; Betroffene: Besucher der Website<br/>
                                                    &#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu
                                                    finden Sie weiter unten bzw. beim Hersteller der Software, der das
                                                    Cookie setzt.<br/>
                                                    &#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten
                                                    Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim
                                                    Hersteller der Software, der das Cookie setzt.<br/>
                                                    &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von
                                                    Stunden bis hin zu Jahren variieren<br/>
                                                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                                                    (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <h3 class="adsimple-312237450">Was sind Cookies?</h3>
                                        <p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu
                                            speichern.<br/>
                                            Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden,
                                            damit Sie die folgende Datenschutzerklärung besser verstehen.</p>
                                        <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser.
                                            Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet
                                            Explorer und Microsoft Edge. Die meisten Websites speichern kleine
                                            Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
                                        <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche
                                            Helferlein. Fast alle Websites verwenden Cookies. Genauer gesprochen sind es
                                            HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche
                                            gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem
                                            Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im
                                            Cookie-Ordner, quasi dem &#8220;Hirn&#8221; Ihres Browsers, untergebracht.
                                            Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines
                                            Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
                                        <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache
                                            oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen,
                                            übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite
                                            zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen
                                            die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie
                                            eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies
                                            in einer einzigen Datei gespeichert.</p>
                                        <p>Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser
                                            wie z. B. Chrome und dem Webserver. Dabei fordert der Webbrowser eine
                                            Website an und erhält vom Server ein Cookie zurück, welches der Browser
                                            erneut verwendet, sobald eine andere Seite angefordert wird.</p>
                                        <p>
                                            <img role="img"
                                                 src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg"
                                                 alt="HTTP Cookie Interaktion zwischen Browser und Webserver"
                                                 width="100%"/>
                                        </p>
                                        <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies.
                                            Erstanbieter-Cookies werden direkt von unserer Seite erstellt,
                                            Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics)
                                            erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere
                                            Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar
                                            Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme
                                            und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können
                                            auch nicht auf Informationen Ihres PCs zugreifen.</p>
                                        <p>So können zum Beispiel Cookie-Daten aussehen:</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> _ga<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> GA1.2.1326744211.152312237450-9<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Unterscheidung
                                            der Websitebesucher<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach 2 Jahren</p>
                                        <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
                                        <ul class="adsimple-312237450">
                                            <li class="adsimple-312237450">Mindestens 4096 Bytes pro Cookie</li>
                                            <li class="adsimple-312237450">Mindestens 50 Cookies pro Domain</li>
                                            <li class="adsimple-312237450">Mindestens 3000 Cookies insgesamt</li>
                                        </ul>
                                        <h3 class="adsimple-312237450">Welche Arten von Cookies gibt es?</h3>
                                        <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den
                                            verwendeten Diensten ab und wird in den folgenden Abschnitten der
                                            Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die
                                            verschiedenen Arten von HTTP-Cookies eingehen.</p>
                                        <p>Man kann 4 Arten von Cookies unterscheiden:</p>
                                        <p>
                                            <strong class="adsimple-312237450">Unerlässliche Cookies<br/>
                                            </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website
                                            sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein
                                            Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und
                                            später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht
                                            gelöscht, selbst wenn der User sein Browserfenster schließt.</p>
                                        <p>
                                            <strong class="adsimple-312237450">Zweckmäßige Cookies<br/>
                                            </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User
                                            etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch
                                            die Ladezeit und das Verhalten der Website bei verschiedenen Browsern
                                            gemessen.</p>
                                        <p>
                                            <strong class="adsimple-312237450">Zielorientierte Cookies<br/>
                                            </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit.
                                            Beispielsweise werden eingegebene Standorte, Schriftgrößen oder
                                            Formulardaten gespeichert.</p>
                                        <p>
                                            <strong class="adsimple-312237450">Werbe-Cookies<br/>
                                            </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen
                                            dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr
                                            praktisch, aber auch sehr nervig sein.</p>
                                        <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt,
                                            welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese
                                            Entscheidung auch in einem Cookie gespeichert.</p>
                                        <p>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen
                                            nicht scheuen, empfehlen wir <a class="adsimple-312237450"
                                                                            href="https://datatracker.ietf.org/doc/html/rfc6265">https://datatracker.ietf.org/doc/html/rfc6265</a>, dem
                                            Request for Comments der Internet Engineering Task Force (IETF) namens
                                            &#8220;HTTP State Management Mechanism&#8221;.</p>
                                        <h3 class="adsimple-312237450">Zweck der Verarbeitung über Cookies</h3>
                                        <p>Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu
                                            finden Sie weiter unten bzw. beim Hersteller der Software, die das Cookie
                                            setzt.</p>
                                        <h3 class="adsimple-312237450">Welche Daten werden verarbeitet?</h3>
                                        <p>Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche
                                            Daten in Cookies gespeichert werden, kann man leider nicht verallgemeinern,
                                            aber wir werden Sie im Rahmen der folgenden Datenschutzerklärung über die
                                            verarbeiteten bzw. gespeicherten Daten informieren.</p>
                                        <h3 class="adsimple-312237450">Speicherdauer von Cookies</h3>
                                        <p>Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter
                                            präzisiert. Manche Cookies werden nach weniger als einer Stunde gelöscht,
                                            andere können mehrere Jahre auf einem Computer gespeichert bleiben.</p>
                                        <p>Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über
                                            ihren Browser sämtliche Cookies jederzeit manuell löschen (siehe auch unten
                                            &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies, die auf einer
                                            Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht,
                                            wobei die Rechtmäßigkeit der Speicherung bis dahin unberührt bleibt.</p>
                                        <h3 class="adsimple-312237450">Widerspruchsrecht &#8211; wie kann ich Cookies
                                            löschen?</h3>
                                        <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig
                                            von welchem Service oder welcher Website die Cookies stammen, haben Sie
                                            immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise
                                            zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren,
                                            aber alle anderen Cookies zulassen.</p>
                                        <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert
                                            wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie
                                            dies in Ihren Browser-Einstellungen finden:</p>
                                        <p>
                                            <a class="adsimple-312237450"
                                               href="https://support.google.com/chrome/answer/95647?tid=312237450"
                                               target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome
                                                löschen, aktivieren und verwalten</a>
                                        </p>
                                        <p>
                                            <a class="adsimple-312237450"
                                               href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=312237450"
                                               target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies
                                                und Websitedaten mit Safari</a>
                                        </p>
                                        <p>
                                            <a class="adsimple-312237450"
                                               href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=312237450"
                                               target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um
                                                Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
                                        </p>
                                        <p>
                                            <a class="adsimple-312237450"
                                               href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=312237450">Internet
                                                Explorer: Löschen und Verwalten von Cookies</a>
                                        </p>
                                        <p>
                                            <a class="adsimple-312237450"
                                               href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=312237450">Microsoft
                                                Edge: Löschen und Verwalten von Cookies</a>
                                        </p>
                                        <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser
                                            so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden
                                            soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das
                                            Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser
                                            verschieden. Am besten Sie suchen die Anleitung in Google mit dem
                                            Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren Chrome&#8221;
                                            im Falle eines Chrome Browsers.</p>
                                        <h3 class="adsimple-312237450">Rechtsgrundlage</h3>
                                        <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist
                                            festgehalten, dass das Speichern von Cookies eine <strong
                                                class="adsimple-312237450">Einwilligung</strong> (Artikel 6 Abs. 1 lit.
                                            a DSGVO) von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch
                                            sehr unterschiedliche Reaktionen auf diese Richtlinien. In Österreich
                                            erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des
                                            Telekommunikationsgesetzes (TKG). In Deutschland wurden die
                                            Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen
                                            erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des
                                            Telemediengesetzes (TMG).</p>
                                        <p>Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt,
                                            bestehen <strong class="adsimple-312237450">berechtigte Interessen</strong>
                                            (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen wirtschaftlicher
                                            Natur sind. Wir möchten den Besuchern der Website eine angenehme
                                            Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt
                                            notwendig.</p>
                                        <p>Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht
                                            dies nur im Falle Ihrer Einwilligung. Rechtsgrundlage ist insoweit Art. 6
                                            Abs. 1 lit. a DSGVO.</p>
                                        <p>In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies
                                            informiert, sofern eingesetzte Software Cookies verwendet.</p>
                                        <h2 id="webhosting-einleitung" class="adsimple-312237450">Webhosting
                                            Einleitung</h2>
                                        <table border="1" cellpadding="15">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong class="adsimple-312237450">Webhosting
                                                        Zusammenfassung</strong>
                                                    <br/>
                                                    &#x1f465; Betroffene: Besucher der Website<br/>
                                                    &#x1f91d; Zweck: professionelles Hosting der Website und Absicherung
                                                    des Betriebs<br/>
                                                    &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des
                                                    Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details
                                                    dazu finden Sie weiter unten bzw. beim jeweils eingesetzten
                                                    Webhosting Provider.<br/>
                                                    &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in
                                                    der Regel 2 Wochen<br/>
                                                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO
                                                    (Berechtigte Interessen)
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <h3 class="adsimple-312237450">Was ist Webhosting?</h3>
                                        <p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211;
                                            auch personenbezogene Daten &#8211; automatisch erstellt und gespeichert, so
                                            auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur mit
                                            Begründung verarbeitet werden. Mit Website meinen wir übrigens die
                                            Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite
                                            (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit
                                            Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
                                        <p>Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen
                                            möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt. Sie
                                            kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft
                                            Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser oder
                                            Webbrowser dazu.</p>
                                        <p>Um die Website anzuzeigen, muss sich der Browser zu einem anderen Computer
                                            verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der
                                            Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe,
                                            weswegen dies in der Regel von professionellen Anbietern, den Providern,
                                            übernommen wird. Diese bieten Webhosting an und sorgen damit für eine
                                            verlässliche und fehlerfreie Speicherung der Daten von Websites. Eine ganze
                                            Menge Fachbegriffe, aber bitte bleiben Sie dran, es wird noch besser!</p>
                                        <p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop,
                                            Tablet oder Smartphone) und während der Datenübertragung zu und vom
                                            Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen.
                                            Einerseits speichert Ihr Computer Daten, andererseits muss auch der
                                            Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu
                                            gewährleisten.</p>
                                        <p>Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur
                                            Veranschaulichung das Zusammenspiel zwischen Browser, dem Internet und dem
                                            Hosting-Provider.</p>
                                        <p>
                                            <img role="img"
                                                 src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg"
                                                 alt="Browser und Webserver" width="100%"/>
                                        </p>
                                        <h3 class="adsimple-312237450">Warum verarbeiten wir personenbezogene
                                            Daten?</h3>
                                        <p>Die Zwecke der Datenverarbeitung sind:</p>
                                        <ol>
                                            <li class="adsimple-312237450">Professionelles Hosting der Website und
                                                Absicherung des Betriebs
                                            </li>
                                            <li class="adsimple-312237450">zur Aufrechterhaltung der Betriebs- und
                                                IT-Sicherheit
                                            </li>
                                            <li class="adsimple-312237450">Anonyme Auswertung des Zugriffsverhaltens zur
                                                Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw.
                                                Verfolgung von Ansprüchen
                                            </li>
                                        </ol>
                                        <h3 class="adsimple-312237450">Welche Daten werden verarbeitet?</h3>
                                        <p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser
                                            Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in
                                            der Regel automatisch Daten wie</p>
                                        <ul class="adsimple-312237450">
                                            <li class="adsimple-312237450">die komplette Internetadresse (URL) der
                                                aufgerufenen Webseite
                                            </li>
                                            <li class="adsimple-312237450">Browser und Browserversion (z. B. Chrome
                                                87)
                                            </li>
                                            <li class="adsimple-312237450">das verwendete Betriebssystem (z. B. Windows
                                                10)
                                            </li>
                                            <li class="adsimple-312237450">die Adresse (URL) der zuvor besuchten Seite
                                                (Referrer URL) (z. B. <a class="adsimple-312237450"
                                                                         href="https://www.beispielquellsite.de/vondabinichgekommen/"
                                                                         target="_blank" rel="follow noopener">https://www.beispielquellsite.de/vondabinichgekommen/</a>)
                                            </li>
                                            <li class="adsimple-312237450">den Hostnamen und die IP-Adresse des Geräts
                                                von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)
                                            </li>
                                            <li class="adsimple-312237450">Datum und Uhrzeit</li>
                                            <li class="adsimple-312237450">in Dateien, den sogenannten
                                                Webserver-Logfiles
                                            </li>
                                        </ul>
                                        <h3 class="adsimple-312237450">Wie lange werden Daten gespeichert?</h3>
                                        <p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und
                                            danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können
                                            jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
                                            rechtswidrigem Verhalten von Behörden eingesehen werden.</p>
                                        <p>
                                            <strong class="adsimple-312237450">Kurz gesagt:</strong> Ihr Besuch wird
                                            durch unseren Provider (Firma, die unsere Website auf speziellen Computern
                                            (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne
                                            Zustimmung weiter!</p>
                                        <h3 class="adsimple-312237450">Rechtsgrundlage</h3>
                                        <p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des
                                            Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der
                                            berechtigten Interessen), denn die Nutzung von professionellem Hosting bei
                                            einem Provider ist notwendig, um das Unternehmen im Internet sicher und
                                            nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus
                                            gegebenenfalls verfolgen zu können.</p>
                                        <p>Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag über
                                            die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung von
                                            Datenschutz gewährleistet und Datensicherheit garantiert.</p>
                                        <h2>Externes Hosting</h2>
                                        <p>Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die
                                            personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf
                                            den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um
                                            IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten,
                                            Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine
                                            Website generiert werden, handeln.</p>
                                        <p>Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber
                                            unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und
                                            im Interesse einer sicheren, schnellen und effizienten Bereitstellung
                                            unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1
                                            lit. f DSGVO).</p>
                                        <p>Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung
                                            seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf
                                            diese Daten befolgen.</p>
                                        <p>Wir setzen folgenden Hoster ein:

                                            checkdomain GmbH
                                            a dogado group company
                                            Große Burgstraße 27/29
                                            23552 Lübeck</p>
                                        <h2 id="messenger-kommunikation-einleitung" class="adsimple-312237450">Messenger
                                            &amp; Kommunikation Einleitung</h2>
                                        <table border="1" cellpadding="15">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong class="adsimple-312237450">Messenger &amp; Kommunikation
                                                        Datenschutzerklärung Zusammenfassung</strong>
                                                    <br/>
                                                    &#x1f465; Betroffene: Besucher der Website<br/>
                                                    &#x1f91d; Zweck: Kontaktanfragen und die allgemeine Kommunikation
                                                    zwischen uns und Ihnen<br/>
                                                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa Name, Adresse,
                                                    E-Mailadresse, Telefonnummer, allgemeine Inhaltsdaten,
                                                    gegebenenfalls IP-Adresse<br/>
                                                    Mehr Details dazu finden Sie bei den jeweils eingesetzten
                                                    Tools.<br/>
                                                    &#x1f4c5; Speicherdauer: abhängig von den verwendeten Messenger-
                                                    &amp; Kommunikationsfunktionen<br/>
                                                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                                                    (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen),
                                                    Art. 6 Abs. 1 S. 1 lit. b. DSGVO (vertragliche oder vorvertragliche
                                                    Verpflichtungen)
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <h3 class="adsimple-312237450">Was sind Messenger- &amp;
                                            Kommunikationsfunktionen?</h3>
                                        <p>Wir bieten auf unserer Website verschiedene Möglichkeiten (etwa Messenger-
                                            und Chatfunktionen, Online- bzw. Kontaktformulare, E-Mail, Telefon), um mit
                                            uns zu kommunizieren. Dabei werden auch Ihre Daten, soweit es für die
                                            Beantwortung Ihrer Anfrage und unseren darauffolgenden Maßnahmen nötig ist,
                                            verarbeitet und gespeichert.</p>
                                        <p>Wir nutzen neben klassischen Kommunikationsmitteln wie E-Mail,
                                            Kontaktformularen oder Telefon auch Chats bzw. Messenger. Die derzeit am
                                            häufigsten verwendete Messenger-Funktion ist WhatsApp, aber es gibt
                                            natürlich speziell für Websites viele verschiedene Anbieter, die
                                            Messenger-Funktionen anbieten. Wenn Inhalte Ende zu Ende verschlüsselt sind,
                                            wird darauf in den einzelnen Datenschutztexten oder in der
                                            Datenschutzerklärung des jeweiligen Anbieters hingewiesen. Eine
                                            Ende-zu-Ende-Verschlüsselung bedeutet nichts anders, als dass Inhalte einer
                                            Nachricht selbst für den Anbieter nicht sichtbar sind. Allerdings können
                                            trotzdem Informationen zu Ihrem Gerät, Standorteinstellungen und andere
                                            technische Daten verarbeitet und gespeichert werden.</p>
                                        <h3 class="adsimple-312237450">Warum nutzen wir Messenger- &amp;
                                            Kommunikationsfunktionen?</h3>
                                        <p>Kommunikationsmöglichkeiten mit Ihnen sind für uns von großer Bedeutung.
                                            Schließlich wollen wir mit Ihnen sprechen und alle möglichen Fragen zu
                                            unserem Service bestmöglich beantworten. Eine gut funktionierende
                                            Kommunikation ist bei uns ein wichtiger Teil unserer Dienstleistung. Mit den
                                            praktischen Messenger- &amp; Kommunikationsfunktionen können Sie jederzeit
                                            jene wählen, die Ihnen am liebsten sind. In Ausnahmefällen kann es aber auch
                                            vorkommen, dass wir bestimmte Fragen über Chat bzw. Messenger nicht
                                            beantworten. Das ist der Fall, wenn es etwa um interne vertragliche
                                            Angelegenheiten geht. Hier empfehlen wir andere Kommunikationsmöglichkeiten
                                            wie E-Mail oder Telefon.</p>
                                        <p>Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich
                                            verantwortlich bleiben, auch wenn wir Dienste einer Social-Media-Plattform
                                            nutzen. Der Europäische Gerichtshof hat jedoch entschieden, dass in
                                            bestimmten Fällen der Betreiber der Social-Media-Plattform zusammen mit uns
                                            gemeinsam verantwortlich im Sinne des Art. 26 DSGVO sein kann. Soweit dies
                                            der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage
                                            einer diesbezüglichen Vereinbarung. Das Wesentliche der Vereinbarung ist
                                            weiter unten bei der betroffenen Plattform wiedergegeben.</p>
                                        <p>Bitte beachten Sie, dass bei der Nutzung unserer eingebauten Elemente auch
                                            Daten von Ihnen außerhalb der Europäischen Union verarbeitet werden können,
                                            da viele Anbieter, beispielsweise Facebook-Messenger oder WhatsApp
                                            amerikanische Unternehmen sind. Dadurch können Sie möglicherweise Ihre
                                            Rechte in Bezug auf Ihre personenbezogenen Daten nicht mehr so leicht
                                            einfordern bzw. durchsetzen.</p>
                                        <h3 class="adsimple-312237450">Welche Daten werden verarbeitet?</h3>
                                        <p>Welche Daten genau gespeichert und verarbeitet werden, hängt vom jeweiligen
                                            Anbieter der Messenger- &amp; Kommunikationsfunktionen ab. Grundsätzlich
                                            handelt es sich um Daten wie etwa Name, Adresse, Telefonnummer,
                                            E-Mailadresse und Inhaltsdaten wie beispielsweise alle Informationen, die
                                            Sie in ein Kontaktformular eingeben. Meistens werden auch Informationen zu
                                            Ihrem Gerät und die IP-Adresse gespeichert. Daten, die über eine Messenger-
                                            &amp; Kommunikationsfunktion erhoben werden, werden auch auf den Servern der
                                            Anbieter gespeichert.</p>
                                        <p>Wenn Sie genau wissen wollen, welche Daten bei den jeweiligen Anbietern
                                            gespeichert und verarbeitet werden und wie Sie der Datenverarbeitung
                                            widersprechen können, sollten Sie die jeweilige Datenschutzerklärung des
                                            Unternehmens sorgfältig durchlesen.</p>
                                        <h3 class="adsimple-312237450">Wie lange werden Daten gespeichert?</h3>
                                        <p>Wie lange die Daten verarbeitet und gespeichert werden, hängt in erster Linie
                                            von unseren verwendeten Tools ab. Weiter unten erfahren Sie mehr über die
                                            Datenverarbeitung der einzelnen Tools. In den Datenschutzerklärungen der
                                            Anbieter steht üblicherweise genau, welche Daten wie lange gespeichert und
                                            verarbeitet werden. Grundsätzlich werden personenbezogene Daten nur so lange
                                            verarbeitet, wie es für die Bereitstellung unserer Dienste nötig ist. Wenn
                                            Daten in Cookies gespeichert werden, variiert die Speicherdauer stark. Die
                                            Daten können gleich nach dem Verlassen einer Website wieder gelöscht werden,
                                            sie können aber auch über mehrere Jahre gespeichert bleiben. Daher sollten
                                            Sie sich jedes einzelnen Cookie im Detail ansehen, wenn Sie über die
                                            Datenspeicherung Genaueres wissen wollen. Meistens finden Sie in den
                                            Datenschutzerklärungen der einzelnen Anbieter auch aufschlussreiche
                                            Informationen über die einzelnen Cookies.</p>
                                        <h3 class="adsimple-312237450">Widerspruchsrecht</h3>
                                        <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur
                                            Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert
                                            entweder über unser Cookie-Management-Tool oder über andere
                                            Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch
                                            Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten,
                                            deaktivieren oder löschen. Für weitere Informationen verweisen wir auf den
                                            Abschnitt zur Einwilligung.</p>
                                        <p>Da bei Messenger- &amp; Kommunikationsfunktionen Cookies zum Einsatz kommen
                                            können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über
                                            Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und
                                            verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen
                                            Tools durchlesen.</p>
                                        <h3 class="adsimple-312237450">Rechtsgrundlage</h3>
                                        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
                                            Messenger- &amp; Kommunikationsfunktionen verarbeitet und gespeichert werden
                                            können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung
                                            <strong class="adsimple-312237450">(Art. 6 Abs. 1 lit. a DSGVO)</strong>.
                                            Wir bearbeiten Ihre Anfrage und verwalten Ihre Daten im Rahmen vertraglicher
                                            oder vorvertraglicher Beziehungen, um unsere vorvertraglichen und
                                            vertraglichen Pflichten zu erfüllen bzw. Anfragen zu beantworten. Grundlage
                                            dafür ist <strong class="adsimple-312237450">Art. 6 Abs. 1 S. 1 lit. b.
                                                DSGVO</strong>. Grundsätzlich werden Ihre Daten bei Vorliegen einer
                                            Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong
                                                class="adsimple-312237450">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an
                                            einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und
                                            Geschäftspartnern gespeichert und verarbeitet.</p>
                                        <h2 id="whatsapp-datenschutzerklaerung" class="adsimple-312237450">WhatsApp
                                            Datenschutzerklärung</h2>
                                        <p>Wir verwenden auf unserer Website den Instant-Messaging-Dienst WhatsApp.
                                            Dienstanbieter ist das amerikanische Unternehmen WhatsApp Inc. ein
                                            Tochterunternehmen von Meta Platforms Inc. Für den europäischen Raum ist das
                                            Unternehmen WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal
                                            Harbour, Dublin 2, Irland verantwortlich.</p>
                                        <p>WhatsApp verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf
                                            hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein
                                            angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies
                                            kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der
                                            Datenverarbeitung einhergehen.</p>
                                        <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten
                                            (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also
                                            insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet
                                            WhatsApp sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3
                                            DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind
                                            von der EU-Kommission bereitgestellte Mustervorlagen und sollen
                                            sicherstellen, dass Ihre Daten auch dann den europäischen
                                            Datenschutzstandards entsprechen, wenn diese in Drittländer (wie
                                            beispielsweise in die USA) überliefert und dort gespeichert werden. Durch
                                            diese Klauseln verpflichtet sich WhatsApp, bei der Verarbeitung Ihrer
                                            relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn
                                            die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese
                                            Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie
                                            finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a.
                                            hier: <a class="adsimple-312237450"
                                                     href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
                                        </p>
                                        <p>Informationen zur Datenübermittlung bei WhatsApp, welche den
                                            Standardvertragsklauseln entsprechen, finden Sie unter <a
                                                class="adsimple-312237450"
                                                href="https://www.whatsapp.com/legal/business-data-transfer-addendum-20210927">https://www.whatsapp.com/legal/business-data-transfer-addendum-20210927</a>
                                        </p>
                                        <p>Mehr über die Daten, die durch die Verwendung von WhatsApp verarbeitet
                                            werden, erfahren Sie in der Privacy Policy auf <a class="adsimple-312237450"
                                                                                              href="https://www.whatsapp.com/privacy">https://www.whatsapp.com/privacy</a>
                                        </p>
                                        <h2 id="social-media-einleitung" class="adsimple-312237450">Social Media
                                            Einleitung</h2>
                                        <table border="1" cellpadding="15">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong class="adsimple-312237450">Social Media Datenschutzerklärung
                                                        Zusammenfassung</strong>
                                                    <br/>
                                                    &#x1f465; Betroffene: Besucher der Website<br/>
                                                    &#x1f91d; Zweck: Darstellung und Optimierung unserer
                                                    Serviceleistung, Kontakt zu Besuchern, Interessenten u.a.,
                                                    Werbung<br/>
                                                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa Telefonnummern,
                                                    E-Mail-Adressen, Kontaktdaten, Daten zum Nutzerverhalten,
                                                    Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br/>
                                                    Mehr Details dazu finden Sie beim jeweils eingesetzten
                                                    Social-Media-Tool.<br/>
                                                    &#x1f4c5; Speicherdauer: abhängig von den verwendeten
                                                    Social-Media-Plattformen<br/>
                                                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                                                    (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <h3 class="adsimple-312237450">Was ist Social Media?</h3>
                                        <p>Zusätzlich zu unserer Website sind wir auch in diversen
                                            Social-Media-Plattformen aktiv. Dabei können Daten von Usern verarbeitet
                                            werden, damit wir gezielt User, die sich für uns interessieren, über die
                                            sozialen Netzwerke ansprechen können. Darüber hinaus können auch Elemente
                                            einer Social-Media-Plattform direkt in unsere Website eingebettet sein. Das
                                            ist etwa der Fall, wenn Sie einen sogenannten Social-Button auf unserer
                                            Website anklicken und direkt zu unserem Social-Media-Auftritt weitergeleitet
                                            werden. Als sogenannte Sozialen Medien oder Social Media werden Websites und
                                            Apps bezeichnet, über die angemeldete Mitglieder Inhalte produzieren,
                                            Inhalte offen oder in bestimmten Gruppen austauschen und sich mit anderen
                                            Mitgliedern vernetzen können.</p>
                                        <h3 class="adsimple-312237450">Warum nutzen wir Social Media?</h3>
                                        <p>Seit Jahren sind Social-Media-Plattformen der Ort, wo Menschen online
                                            kommunizieren und in Kontakt treten. Mit unseren Social-Media-Auftritten
                                            können wir unsere Produkte und Dienstleistungen Interessenten näherbringen.
                                            Die auf unserer Website eingebundenen Social-Media-Elemente helfen Ihnen,
                                            schnell und ohne Komplikationen zu unseren Social-Media-Inhalten wechseln
                                            können.</p>
                                        <p>Die Daten, die durch Ihre Nutzung eines Social-Media-Kanals gespeichert und
                                            verarbeitet werden, haben in erster Linie den Zweck, Webanalysen durchführen
                                            zu können. Ziel dieser Analysen ist es, genauere und personenbezogene
                                            Marketing- und Werbestrategien entwickeln zu können. Abhängig von Ihrem
                                            Verhalten auf einer Social-Media-Plattform, können mit Hilfe der
                                            ausgewerteten Daten, passende Rückschlüsse auf Ihre Interessen getroffen
                                            werden und sogenannte Userprofile erstellt werden. So ist es den Plattformen
                                            auch möglich, Ihnen maßgeschneiderte Werbeanzeigen zu präsentieren. Meistens
                                            werden für diesen Zweck Cookies in Ihrem Browser gesetzt, die Daten zu Ihrem
                                            Nutzungsverhalten speichern.</p>
                                        <p>Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich
                                            verantwortlich bleiben, auch wenn wir Dienste einer Social-Media-Plattform
                                            nutzen. Der Europäische Gerichtshof hat jedoch entschieden, dass in
                                            bestimmten Fällen der Betreiber der Social-Media-Plattform zusammen mit uns
                                            gemeinsam verantwortlich im Sinne des Art. 26 DSGVO sein kann. Soweit dies
                                            der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage
                                            einer diesbezüglichen Vereinbarung. Das Wesentliche der Vereinbarung ist
                                            dann weiter unten bei der betroffenen Plattform wiedergegeben.</p>
                                        <p>Bitte beachten Sie, dass bei der Nutzung der Social-Media-Plattformen oder
                                            unserer eingebauten Elemente auch Daten von Ihnen außerhalb der Europäischen
                                            Union verarbeitet werden können, da viele Social-Media-Kanäle,
                                            beispielsweise Facebook oder Twitter, amerikanische Unternehmen sind.
                                            Dadurch können Sie möglicherweise Ihre Rechte in Bezug auf Ihre
                                            personenbezogenen Daten nicht mehr so leicht einfordern bzw.
                                            durchsetzen.</p>
                                        <h3 class="adsimple-312237450">Welche Daten werden verarbeitet?</h3>
                                        <p>Welche Daten genau gespeichert und verarbeitet werden, hängt vom jeweiligen
                                            Anbieter der Social-Media-Plattform ab. Aber für gewöhnlich handelt es sich
                                            um Daten wie etwa Telefonnummern, E-Mailadressen, Daten, die Sie in ein
                                            Kontaktformular eingeben, Nutzerdaten wie zum Beispiel welche Buttons Sie
                                            klicken, wen Sie liken oder wem folgen, wann Sie welche Seiten besucht
                                            haben, Informationen zu Ihrem Gerät und Ihre IP-Adresse. Die meisten dieser
                                            Daten werden in Cookies gespeichert. Speziell wenn Sie selbst ein Profil bei
                                            dem besuchten Social-Media-Kanal haben und angemeldet sind, können Daten mit
                                            Ihrem Profil verknüpft werden.</p>
                                        <p>Alle Daten, die über eine Social-Media-Plattform erhoben werden, werden auch
                                            auf den Servern der Anbieter gespeichert. Somit haben auch nur die Anbieter
                                            Zugang zu den Daten und können Ihnen die passenden Auskünfte geben bzw.
                                            Änderungen vornehmen.</p>
                                        <p>Wenn Sie genau wissen wollen, welche Daten bei den Social-Media-Anbietern
                                            gespeichert und verarbeitet werden und wie sie der Datenverarbeitung
                                            widersprechen können, sollten Sie die jeweilige Datenschutzerklärung des
                                            Unternehmens sorgfältig durchlesen. Auch wenn Sie zur Datenspeicherung und
                                            Datenverarbeitung Fragen haben oder entsprechende Rechte geltend machen
                                            wollen, empfehlen wir Ihnen, sich direkt an den Anbieter wenden.</p>
                                        <h3 class="adsimple-312237450">
                                            <span class="adsimple-312237450"
                                                  data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wo und wie lange werden Daten gespeichert?&quot;}"
                                                  data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">Dauer der Datenverarbeitung</span>
                                        </h3>
                                        <p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern
                                            wir weitere Informationen dazu haben. Beispielsweise speichert die
                                            Social-Media-Plattform Facebook Daten, bis sie für den eigenen Zweck nicht
                                            mehr benötigt werden. Kundendaten, die mit den eigenen Userdaten abgeglichen
                                            werden, werden aber schon innerhalb von zwei Tagen gelöscht. Generell
                                            verarbeiten wir personenbezogene Daten nur so lange wie es für die
                                            Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig
                                            ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich
                                            vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden.</p>
                                        <h3 class="adsimple-312237450">Widerspruchsrecht</h3>
                                        <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur
                                            Verwendung von Cookies bzw. Drittanbietern wie eingebettete
                                            Social-Media-Elemente zu widerrufen. Das funktioniert entweder über unser
                                            Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel
                                            können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in
                                            Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
                                        <p>Da bei Social-Media-Tools Cookies zum Einsatz kommen können, empfehlen wir
                                            Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu
                                            erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden,
                                            sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
                                        <h3 class="adsimple-312237450">Rechtsgrundlage</h3>
                                        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
                                            Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese
                                            Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong
                                                class="adsimple-312237450">(Art. 6 Abs. 1 lit. a DSGVO)</strong>.
                                            Grundsätzlich werden Ihre Daten bei Vorliegen einer Einwilligung auch auf
                                            Grundlage unseres berechtigten Interesses <strong
                                                class="adsimple-312237450">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an
                                            einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und
                                            Geschäftspartnern gespeichert und verarbeitet. Wir setzen die Tools
                                            gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten
                                            Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu
                                            speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies
                                            genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien
                                            des jeweiligen Dienstanbieters anzusehen.</p>
                                        <p>Informationen zu speziellen Social-Media-Plattformen erfahren Sie &#8211;
                                            sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
                                        <h2 id="facebook-datenschutzerklaerung" class="adsimple-312237450">Facebook
                                            Datenschutzerklärung</h2>
                                        <table border="1" cellpadding="15">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong class="adsimple-312237450">Facebook Datenschutzerklärung
                                                        Zusammenfassung</strong>
                                                    <br/>
                                                    &#x1f465; Betroffene: Besucher der Website<br/>
                                                    &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br/>
                                                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa Kundendaten, Daten zum
                                                    Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre
                                                    IP-Adresse.<br/>
                                                    Mehr Details dazu finden Sie weiter unten in der
                                                    Datenschutzerklärung.<br/>
                                                    &#x1f4c5; Speicherdauer: bis die Daten für Facebooks Zwecke nicht
                                                    mehr nützlich sind<br/>
                                                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                                                    (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <h3 class="adsimple-312237450">Was sind Facebook-Tools?</h3>
                                        <p>Wir verwenden auf unserer Website ausgewählte Tools von Facebook. Facebook
                                            ist ein Social Media Network des Unternehmens Meta Platforms Inc. bzw. für
                                            den europäischen Raum des Unternehmens Meta Platforms Ireland Limited, 4
                                            Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland. Mithilfe dieser
                                            Tools können wir Ihnen und Menschen, die sich für unsere Produkte und
                                            Dienstleistungen interessieren, das bestmögliche Angebot bieten.</p>
                                        <p>Wenn über unsere eingebetteten Facebook-Elemente oder über unsere
                                            Facebook-Seite (Fanpage) Daten von Ihnen erhoben und weitergeleitet werden,
                                            sind sowohl wir als auch Facebook Irland Ltd. dafür verantwortlich. Für die
                                            weitere Verarbeitung dieser Daten trägt Facebook allein die Verantwortung.
                                            Unsere gemeinsamen Verpflichtungen wurden auch in einer öffentlich
                                            zugänglichen Vereinbarung unter <a class="adsimple-312237450"
                                                                               href="https://www.facebook.com/legal/controller_addendum">https://www.facebook.com/legal/controller_addendum</a>
                                            verankert. Darin ist etwa festgehalten, dass wir Sie klar über den Einsatz
                                            der Facebook-Tools auf unserer Seite informieren müssen. Weiters sind wir
                                            auch dafür verantwortlich, dass die Tools datenschutzrechtlich sicher in
                                            unsere Website eingebunden sind. Facebook ist hingegen beispielsweise für
                                            die Datensicherheit der Facebook-Produkte verantwortlich. Bei etwaigen
                                            Fragen zur Datenerhebung und Datenverarbeitung durch Facebook können Sie
                                            sich direkt an das Unternehmen wenden. Wenn Sie die Frage an uns richten,
                                            sind wir dazu verpflichtet diese an Facebook weiterleiten.</p>
                                        <p>Im Folgenden geben wir einen Überblick über die verschiedenen Facebook Tools,
                                            welche Daten an Facebook gesendet werden und wie Sie diese Daten löschen
                                            können.</p>
                                        <p>Neben vielen anderen Produkten bietet Facebook auch die sogenannten &#8220;Facebook
                                            Business Tools&#8221; an. Das ist die offizielle Bezeichnung von Facebook.
                                            Da der Begriff aber kaum bekannt ist, haben wir uns dafür entschieden, sie
                                            lediglich Facebook-Tools zu nennen. Darunter finden sich unter anderem:</p>
                                        <ul class="adsimple-312237450">
                                            <li class="adsimple-312237450">Facebook-Pixel</li>
                                            <li class="adsimple-312237450">soziale Plug-ins (wie z.B der „Gefällt mir“-
                                                oder „Teilen“-Button)
                                            </li>
                                            <li class="adsimple-312237450">Facebook Login</li>
                                            <li class="adsimple-312237450">Account Kit</li>
                                            <li class="adsimple-312237450">APIs (Programmierschnittstelle)</li>
                                            <li class="adsimple-312237450">SDKs (Sammlung von Programmierwerkzeugen)
                                            </li>
                                            <li class="adsimple-312237450">Plattform-Integrationen</li>
                                            <li class="adsimple-312237450">Plugins</li>
                                            <li class="adsimple-312237450">Codes</li>
                                            <li class="adsimple-312237450">Spezifikationen</li>
                                            <li class="adsimple-312237450">Dokumentationen</li>
                                            <li class="adsimple-312237450">Technologien und Dienstleistungen</li>
                                        </ul>
                                        <p>Durch diese Tools erweitert Facebook Dienstleistungen und hat die
                                            Möglichkeit, Informationen über User-Aktivitäten außerhalb von Facebook zu
                                            erhalten.</p>
                                        <h3 class="adsimple-312237450">Warum verwenden wir Facebook-Tools auf unserer
                                            Website?</h3>
                                        <p>Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen, die sich
                                            auch wirklich dafür interessieren. Mithilfe von Werbeanzeigen (Facebook-Ads)
                                            können wir genau diese Menschen erreichen. Damit den Usern passende Werbung
                                            gezeigt werden kann, benötigt Facebook allerdings Informationen über die
                                            Wünsche und Bedürfnisse der Menschen. So werden dem
                                            Unternehmen Informationen über das Userverhalten (und Kontaktdaten) auf
                                            unserer Webseite zur Verfügung gestellt. Dadurch sammelt Facebook bessere
                                            User-Daten und kann interessierten Menschen die passende Werbung über unsere
                                            Produkte bzw. Dienstleistungen anzeigen. Die Tools ermöglichen somit
                                            maßgeschneiderte Werbekampagnen auf Facebook.</p>
                                        <p>Daten über Ihr Verhalten auf unserer Webseite nennt Facebook „Event-Daten“.
                                            Diese werden auch für Messungs- und Analysedienste verwendet. Facebook kann
                                            so in unserem Auftrag „Kampagnenberichte“ über die Wirkung unserer
                                            Werbekampagnen erstellen. Weiters bekommen wir durch Analysen einen besseren
                                            Einblick, wie Sie unsere Dienstleistungen, Webseite oder Produkte verwenden.
                                            Dadurch optimieren wir mit einigen dieser Tools Ihre Nutzererfahrung auf
                                            unserer Webseite. Beispielsweise können Sie mit den sozialen Plug-ins
                                            Inhalte auf unserer Seite direkt auf Facebook teilen.</p>
                                        <h3 class="adsimple-312237450">Welche Daten werden von Facebook-Tools
                                            gespeichert?</h3>
                                        <p>Durch die Nutzung einzelner Facebook-Tools können personenbezogene Daten
                                            (Kundendaten) an Facebook gesendet werden. Abhängig von den benutzten Tools
                                            können Kundendaten wie Name, Adresse, Telefonnummer und IP-Adresse versandt
                                            werden.</p>
                                        <p>Facebook verwendet diese Informationen, um die Daten mit den Daten, die es
                                            selbst von Ihnen hat (sofern Sie Facebook-Mitglied sind) abzugleichen. Bevor
                                            Kundendaten an Facebook übermittelt werden, erfolgt ein sogenanntes
                                            „Hashing“. Das bedeutet, dass ein beliebig großer Datensatz in eine
                                            Zeichenkette transformiert wird. Dies dient auch der Verschlüsselung von
                                            Daten.</p>
                                        <p>Neben den Kontaktdaten werden auch „Event-Daten“ übermittelt. Unter
                                            „Event-Daten“ sind jene Informationen gemeint, die wir über Sie auf unserer
                                            Webseite erhalten. Zum Beispiel, welche Unterseiten Sie besuchen oder welche
                                            Produkte Sie bei uns kaufen. Facebook teilt die erhaltenen Informationen
                                            nicht mit Drittanbietern (wie beispielsweise Werbetreibende), außer das
                                            Unternehmen hat eine explizite Genehmigung oder ist rechtlich dazu
                                            verpflichtet. „Event-Daten“ können auch mit Kontaktdaten verbunden werden.
                                            Dadurch kann Facebook bessere personalisierte Werbung anbieten. Nach dem
                                            bereits erwähnten Abgleichungsprozess löscht Facebook die Kontaktdaten
                                            wieder.</p>
                                        <p>Um Werbeanzeigen optimiert ausliefern zu können, verwendet Facebook die
                                            Event-Daten nur, wenn diese mit anderen Daten (die auf andere Weise von
                                            Facebook erfasst wurden) zusammengefasst wurden. Diese Event-Daten nützt
                                            Facebook auch für Sicherheits-, Schutz-, Entwicklungs- und Forschungszwecke.
                                            Viele dieser Daten werden über Cookies zu Facebook übertragen. Cookies sind
                                            kleine Text-Dateien, die zum Speichern von Daten bzw. Informationen in
                                            Browsern verwendet werden. Je nach verwendeten Tools und abhängig davon, ob
                                            Sie Facebook-Mitglied sind, werden unterschiedlich viele Cookies in Ihrem
                                            Browser angelegt. In den Beschreibungen der einzelnen Facebook Tools gehen
                                            wir näher auf einzelne Facebook-Cookies ein. Allgemeine Informationen über
                                            die Verwendung von Facebook-Cookies erfahren Sie auch auf <a
                                                class="adsimple-312237450"
                                                href="https://www.facebook.com/policies/cookies?tid=312237450">https://www.facebook.com/policies/cookies</a>.
                                        </p>
                                        <h3 class="adsimple-312237450">Wie lange und wo werden die Daten
                                            gespeichert?</h3>
                                        <p>Grundsätzlich speichert Facebook Daten bis sie nicht mehr für die eigenen
                                            Dienste und Facebook-Produkte benötigt werden. Facebook hat auf der ganzen
                                            Welt Server verteilt, wo seine Daten gespeichert werden. Kundendaten werden
                                            allerdings, nachdem sie mit den eigenen Userdaten abgeglichen wurden,
                                            innerhalb von 48 Stunden gelöscht.</p>
                                        <h3 class="adsimple-312237450">Wie kann ich meine Daten löschen bzw. die
                                            Datenspeicherung verhindern?</h3>
                                        <p>Entsprechend der Datenschutz Grundverordnung haben Sie das Recht auf
                                            Auskunft, Berichtigung, Übertragbarkeit und Löschung Ihrer Daten.</p>
                                        <p>Eine komplette Löschung der Daten erfolgt nur, wenn Sie Ihr Facebook-Konto
                                            vollständig löschen. Und so funktioniert das Löschen Ihres
                                            Facebook-Kontos:</p>
                                        <p>1) Klicken Sie rechts bei Facebook auf Einstellungen.</p>
                                        <p>2) Anschließend klicken Sie in der linken Spalte auf „Deine
                                            Facebook-Informationen“.</p>
                                        <p>3) Nun klicken Sie &#8220;Deaktivierung und Löschung&#8221;.</p>
                                        <p>4) Wählen Sie jetzt „Konto löschen“ und klicken Sie dann auf „Weiter und
                                            Konto löschen“</p>
                                        <p>5) Geben Sie nun Ihr Passwort ein, klicken Sie auf „Weiter“ und dann auf
                                            „Konto löschen“</p>
                                        <p>Die Speicherung der Daten, die Facebook über unsere Seite erhält, erfolgt
                                            unter anderem über Cookies (z.B. bei sozialen Plugins). In Ihrem Browser
                                            können Sie einzelne oder alle Cookies deaktivieren, löschen oder verwalten.
                                            Je nach dem welchen Browser Sie verwenden, funktioniert dies auf
                                            unterschiedliche Art und Weise. Unter dem Abschnitt „Cookies“ finden Sie die
                                            entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten
                                            Browser.</p>
                                        <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser
                                            so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden
                                            soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es
                                            erlauben oder nicht.</p>
                                        <h3 class="adsimple-312237450">Rechtsgrundlage</h3>
                                        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
                                            Facebook-Tools verarbeitet und gespeichert werden können, gilt diese
                                            Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong
                                                class="adsimple-312237450">(Art. 6 Abs. 1 lit. a DSGVO)</strong>.
                                            Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten
                                            Interesses <strong class="adsimple-312237450">(Art. 6 Abs. 1 lit. f
                                                DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen
                                            oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir
                                            setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt
                                            haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem
                                            Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren
                                            Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung
                                            oder die Cookie-Richtlinien von Facebook anzusehen.</p>
                                        <p>Facebook verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf
                                            hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein
                                            angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies
                                            kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der
                                            Datenverarbeitung einhergehen.</p>
                                        <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten
                                            (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also
                                            insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet
                                            Facebook sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3
                                            DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind
                                            von der EU-Kommission bereitgestellte Mustervorlagen und sollen
                                            sicherstellen, dass Ihre Daten auch dann den europäischen
                                            Datenschutzstandards entsprechen, wenn diese in Drittländer (wie
                                            beispielsweise in die USA) überliefert und dort gespeichert werden. Durch
                                            diese Klauseln verpflichtet sich Facebook, bei der Verarbeitung Ihrer
                                            relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn
                                            die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese
                                            Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie
                                            finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a.
                                            hier: <a class="adsimple-312237450"
                                                     href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
                                                     target="_blank" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
                                        </p>
                                        <p>Die Facebook Datenverarbeitungsbedingung, welche den Standardvertragsklauseln
                                            entsprechen, finden Sie unter <a class="adsimple-312237450"
                                                                             href="https://www.facebook.com/legal/terms/dataprocessing">https://www.facebook.com/legal/terms/dataprocessing</a>.
                                        </p>
                                        <p>Wir hoffen, wir haben Ihnen die wichtigsten Informationen über die Nutzung
                                            und Datenverarbeitung durch die Facebook-Tools nähergebracht. Wenn Sie mehr
                                            darüber erfahren wollen, wie Facebook Ihre Daten verwendet, empfehlen wir
                                            Ihnen die Datenrichtlinien auf <a class="adsimple-312237450"
                                                                              href="https://www.facebook.com/about/privacy/update">https://www.facebook.com/about/privacy/update</a>.
                                        </p>
                                        <h2 id="instagram-datenschutzerklaerung" class="adsimple-312237450">Instagram
                                            Datenschutzerklärung</h2>
                                        <table border="1" cellpadding="15">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong class="adsimple-312237450">Instagram Datenschutzerklärung
                                                        Zusammenfassung</strong>
                                                    <br/>
                                                    &#x1f465; Betroffene: Besucher der Website<br/>
                                                    &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br/>
                                                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa Daten zum
                                                    Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre
                                                    IP-Adresse.<br/>
                                                    Mehr Details dazu finden Sie weiter unten in der
                                                    Datenschutzerklärung.<br/>
                                                    &#x1f4c5; Speicherdauer: bis Instagram die Daten für ihre Zwecke
                                                    nicht mehr benötigt<br/>
                                                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                                                    (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <h3 class="adsimple-312237450">Was ist Instagram?</h3>
                                        <p>Wir haben auf unserer Webseite Funktionen von Instagram eingebaut. Instagram
                                            ist eine Social Media Plattform des Unternehmens Instagram LLC, 1601 Willow
                                            Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012 ein Tochterunternehmen
                                            von Meta Platforms Inc. und gehört zu den Facebook-Produkten. Das Einbetten
                                            von Instagram-Inhalten auf unserer Webseite nennt man Embedding. Dadurch
                                            können wir Ihnen Inhalte wie Buttons, Fotos oder Videos von Instagram direkt
                                            auf unserer Webseite zeigen. Wenn Sie Webseiten unserer Webpräsenz aufrufen,
                                            die eine Instagram-Funktion integriert haben, werden Daten an Instagram
                                            übermittelt, gespeichert und verarbeitet. Instagram verwendet dieselben
                                            Systeme und Technologien wie Facebook. Ihre Daten werden somit über alle
                                            Facebook-Firmen hinweg verarbeitet.</p>
                                        <p>Im Folgenden wollen wir Ihnen einen genaueren Einblick geben, warum Instagram
                                            Daten sammelt, um welche Daten es sich handelt und wie Sie die
                                            Datenverarbeitung weitgehend kontrollieren können. Da Instagram zu Meta
                                            Platforms Inc. gehört, beziehen wir unsere Informationen einerseits von den
                                            Instagram-Richtlinien, andererseits allerdings auch von den
                                            Meta-Datenschutzrichtlinien selbst.</p>
                                        <p>Instagram ist eines der bekanntesten Social Media Netzwerken weltweit.
                                            Instagram kombiniert die Vorteile eines Blogs mit den Vorteilen von
                                            audiovisuellen Plattformen wie YouTube oder Vimeo. Sie können auf „Insta“
                                            (wie viele der User die Plattform salopp nennen) Fotos und kurze Videos
                                            hochladen, mit verschiedenen Filtern bearbeiten und auch in anderen sozialen
                                            Netzwerken verbreiten. Und wenn Sie selbst nicht aktiv sein wollen, können
                                            Sie auch nur anderen interessante Users folgen.</p>
                                        <h3 class="adsimple-312237450">Warum verwenden wir Instagram auf unserer
                                            Website?</h3>
                                        <p>Instagram ist jene Social Media Plattform, die in den letzten Jahren so
                                            richtig durch die Decke ging. Und natürlich haben auch wir auf diesen Boom
                                            reagiert. Wir wollen, dass Sie sich auf unserer Webseite so wohl wie möglich
                                            fühlen. Darum ist für uns eine abwechslungsreiche Aufbereitung unserer
                                            Inhalte selbstverständlich. Durch die eingebetteten Instagram-Funktionen
                                            können wir unseren Content mit hilfreichen, lustigen oder spannenden
                                            Inhalten aus der Instagram-Welt bereichern. Da Instagram eine
                                            Tochtergesellschaft von Facebook ist, können uns die erhobenen Daten auch
                                            für personalisierte Werbung auf Facebook dienlich sein. So bekommen unsere
                                            Werbeanzeigen nur Menschen, die sich wirklich für unsere Produkte oder
                                            Dienstleistungen interessieren.</p>
                                        <p>Instagram nützt die gesammelten Daten auch zu Messungs- und Analysezwecken.
                                            Wir bekommen zusammengefasste Statistiken und so mehr Einblick über Ihre
                                            Wünsche und Interessen. Wichtig ist zu erwähnen, dass diese Berichte Sie
                                            nicht persönlich identifizieren.</p>
                                        <h3 class="adsimple-312237450">Welche Daten werden von Instagram
                                            gespeichert?</h3>
                                        <p>Wenn Sie auf eine unserer Seiten stoßen, die Instagram-Funktionen (wie
                                            Instagrambilder oder Plug-ins) eingebaut haben, setzt sich Ihr Browser
                                            automatisch mit den Servern von Instagram in Verbindung. Dabei werden Daten
                                            an Instagram versandt, gespeichert und verarbeitet. Und zwar unabhängig, ob
                                            Sie ein Instagram-Konto haben oder nicht. Dazu zählen Informationen über
                                            unserer Webseite, über Ihren Computer, über getätigte Käufe, über
                                            Werbeanzeigen, die Sie sehen und wie Sie unser Angebot nutzen. Weiters
                                            werden auch Datum und Uhrzeit Ihrer Interaktion mit Instagram gespeichert.
                                            Wenn Sie ein Instagram-Konto haben bzw. eingeloggt sind, speichert Instagram
                                            deutlich mehr Daten über Sie.</p>
                                        <p>Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen davon
                                            aus, dass dies bei Instagram genau so der Fall ist. Kundendaten sind zum
                                            Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Diese Kundendaten
                                            werden erst an Instagram übermittelt werden, wenn Sie zuvor „gehasht“
                                            wurden. Hashing meint, ein Datensatz wird in eine Zeichenkette verwandelt.
                                            Dadurch kann man die Kontaktdaten verschlüsseln. Zudem werden auch die oben
                                            genannten „Event-Daten“ übermittelt. Unter „Event-Daten“ versteht Facebook –
                                            und folglich auch Instagram – Daten über Ihr Userverhalten. Es kann auch
                                            vorkommen, dass Kontaktdaten mit Event-Daten kombiniert werden. Die
                                            erhobenen Kontaktdaten werden mit den Daten, die Instagram bereits von Ihnen
                                            hat, abgeglichen.</p>
                                        <p>Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt
                                            werden, werden die gesammelten Daten an Facebook übermittelt. Je nach
                                            verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto
                                            haben, werden unterschiedlich viele Daten gespeichert.</p>
                                        <p>Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich
                                            funktioniert wie bei Facebook. Das bedeutet: wenn Sie ein Instagram-Konto
                                            haben oder <a class="adsimple-312237450"
                                                          href="https://www.instagram.com/?tid=312237450"
                                                          target="_blank"
                                                          rel="noopener noreferrer">www.instagram.com</a> besucht haben,
                                            hat Instagram zumindest ein Cookie gesetzt. Wenn das der Fall ist, sendet
                                            Ihr Browser über das Cookie Infos an Instagram, sobald Sie mit einer
                                            Instagram-Funktion in Berührung kommen. Spätestens nach 90 Tagen (nach
                                            Abgleichung) werden diese Daten wieder gelöscht bzw. anonymisiert. Obwohl
                                            wir uns intensiv mit der Datenverarbeitung von Instagram beschäftigt haben,
                                            können wir nicht ganz genau sagen, welche Daten Instagram exakt sammelt und
                                            speichert.</p>
                                        <p>Im Folgenden zeigen wir Ihnen Cookies, die in Ihrem Browser mindestens
                                            gesetzt werden, wenn Sie auf eine Instagram-Funktion (wie z.B. Button oder
                                            ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus, dass Sie kein
                                            Instagram-Konto haben. Wenn Sie bei Instagram eingeloggt sind, werden
                                            natürlich deutlich mehr Cookies in Ihrem Browser gesetzt.</p>
                                        <p>Diese Cookies wurden bei unserem Test verwendet:</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name: </strong>csrftoken<br/>
                                            <strong class="adsimple-312237450">Wert: </strong>&#8220;&#8221;<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck: </strong>Dieses Cookie
                                            wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen gesetzt, um
                                            Fälschungen von Anfragen zu verhindern. Genauer konnten wir das allerdings
                                            nicht in Erfahrung bringen.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach einem Jahr</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name: </strong>mid<br/>
                                            <strong class="adsimple-312237450">Wert: </strong>&#8220;&#8221;<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck: </strong>Instagram
                                            setzt dieses Cookie, um die eigenen Dienstleistungen und Angebote in und
                                            außerhalb von Instagram zu optimieren. Das Cookie legt eine eindeutige
                                            User-ID fest.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach Ende der
                                            Sitzung</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> fbsr_312237450124024<br/>
                                            <strong class="adsimple-312237450">Wert: </strong>keine Angaben<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck: </strong>Dieses Cookie
                                            speichert die Log-in-Anfrage für User der Instagram-App.<strong
                                            class="adsimple-312237450">
                                            <br/>
                                        </strong>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach Ende der
                                            Sitzung</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> rur<br/>
                                            <strong class="adsimple-312237450">Wert: </strong>ATN<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck: </strong>Dabei handelt
                                            es sich um ein Instagram-Cookie, das die Funktionalität auf Instagram
                                            gewährleistet.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach Ende der
                                            Sitzung</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> urlgen<br/>
                                            <strong class="adsimple-312237450">Wert: </strong>&#8220;{&#8221;194.96.75.33&#8221;:
                                            1901}:1iEtYv:Y833k2_UjKvXgYe312237450&#8221;<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck: </strong>Dieses Cookie
                                            dient den Marketingzwecken von Instagram.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach Ende der
                                            Sitzung</p>
                                        <p>
                                            <strong class="adsimple-312237450">Anmerkung:</strong> Wir können hier
                                            keinen Vollständigkeitsanspruch erheben. Welche Cookies im individuellen
                                            Fall gesetzt werden, hängt von den eingebetteten Funktionen und Ihrer
                                            Verwendung von Instagram ab.</p>
                                        <h3 class="adsimple-312237450">Wie lange und wo werden die Daten
                                            gespeichert?</h3>
                                        <p>Instagram teilt die erhaltenen Informationen zwischen den
                                            Facebook-Unternehmen mit externen Partnern und mit Personen, mit denen Sie
                                            sich weltweit verbinden. Die Datenverarbeitung erfolgt unter Einhaltung der
                                            eigenen Datenrichtlinie. Ihre Daten sind, unter anderem aus
                                            Sicherheitsgründen, auf den Facebook-Servern auf der ganzen Welt verteilt.
                                            Die meisten dieser Server stehen in den USA.</p>
                                        <h3 class="adsimple-312237450">Wie kann ich meine Daten löschen bzw. die
                                            Datenspeicherung verhindern?</h3>
                                        <p>Dank der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft,
                                            Übertragbarkeit, Berichtigung und Löschung Ihrer Daten. In den
                                            Instagram-Einstellungen können Sie Ihre Daten verwalten. Wenn Sie Ihre Daten
                                            auf Instagram völlig löschen wollen, müssen Sie Ihr Instagram-Konto
                                            dauerhaft löschen.</p>
                                        <p>Und so funktioniert die Löschung des Instagram-Kontos:</p>
                                        <p>Öffnen Sie zuerst die Instagram-App. Auf Ihrer Profilseite gehen Sie nach
                                            unten und klicken Sie auf „Hilfebereich“. Jetzt kommen Sie auf die Webseite
                                            des Unternehmens. Klicken Sie auf der Webseite auf „Verwalten des Kontos“
                                            und dann auf „Dein Konto löschen“.</p>
                                        <p>Wenn Sie Ihr Konto ganz löschen, löscht Instagram Posts wie beispielsweise
                                            Ihre Fotos und Status-Updates. Informationen, die andere Personen über Sie
                                            geteilt haben, gehören nicht zu Ihrem Konto und werden folglich nicht
                                            gelöscht.</p>
                                        <p>Wie bereits oben erwähnt, speichert Instagram Ihre Daten in erster Linie über
                                            Cookies. Diese Cookies können Sie in Ihrem Browser verwalten, deaktivieren
                                            oder löschen. Abhängig von Ihrem Browser funktioniert die Verwaltung immer
                                            ein bisschen anders. Unter dem Abschnitt „Cookies“ finden Sie die
                                            entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten
                                            Browser.</p>
                                        <p>Sie können auch grundsätzlich Ihren Browser so einrichten, dass Sie immer
                                            informiert werden, wenn ein Cookie gesetzt werden soll. Dann können Sie
                                            immer individuell entscheiden, ob Sie das Cookie zulassen wollen oder
                                            nicht.</p>
                                        <h3 class="adsimple-312237450">Rechtsgrundlage</h3>
                                        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
                                            Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese
                                            Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong
                                                class="adsimple-312237450">(Art. 6 Abs. 1 lit. a DSGVO)</strong>.
                                            Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten
                                            Interesses <strong class="adsimple-312237450">(Art. 6 Abs. 1 lit. f
                                                DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen
                                            oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir
                                            setzen die eingebundene Social-Media-Elemente gleichwohl nur ein, soweit Sie
                                            eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen
                                            auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir
                                            Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die
                                            Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen
                                            Dienstanbieters anzusehen.</p>
                                        <p>Instagram bzw. Facebook verarbeitet Daten u.a. auch in den USA. Wir weisen
                                            darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein
                                            angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies
                                            kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der
                                            Datenverarbeitung einhergehen.</p>
                                        <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten
                                            (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also
                                            insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet
                                            Facebook von der EU-Kommission genehmigte Standardvertragsklauseln (= Art.
                                            46. Abs. 2 und 3 DSGVO). Diese Klauseln verpflichten Facebook, das
                                            EU-Datenschutzniveau bei der Verarbeitung relevanter Daten auch außerhalb
                                            der EU einzuhalten. Diese Klauseln basieren auf einem Durchführungsbeschluss
                                            der EU-Kommission. Sie finden den Beschluss sowie die Klauseln u.a. hier: <a
                                                class="adsimple-312237450"
                                                href="https://germany.representation.ec.europa.eu/index_de"
                                                target="_blank" rel="follow noopener">https://germany.representation.ec.europa.eu/index_de</a>.
                                        </p>
                                        <p>Wir haben versucht, Ihnen die wichtigsten Informationen über die
                                            Datenverarbeitung durch Instagram näherzubringen. Auf <a
                                                class="adsimple-312237450"
                                                href="https://help.instagram.com/519522125107875">https://help.instagram.com/519522125107875</a>
                                            <br/>
                                            können Sie sich noch näher mit den Datenrichtlinien von Instagram
                                            auseinandersetzen.</p>
                                        <h2 id="audio-video-einleitung" class="adsimple-312237450">Audio &amp; Video
                                            Einleitung</h2>
                                        <table border="1" cellpadding="15">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong class="adsimple-312237450">Audio &amp; Video
                                                        Datenschutzerklärung Zusammenfassung</strong>
                                                    <br/>
                                                    &#x1f465; Betroffene: Besucher der Website<br/>
                                                    &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br/>
                                                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum
                                                    Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse
                                                    können gespeichert werden.<br/>
                                                    Mehr Details dazu finden Sie weiter unten in den entsprechenden
                                                    Datenschutztexten.<br/>
                                                    &#x1f4c5; Speicherdauer: Daten bleiben grundsätzlich gespeichert,
                                                    solange sie für den Dienstzweck nötig sind<br/>
                                                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                                                    (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <h3 class="adsimple-312237450">Was sind Audio- und Videoelemente?</h3>
                                        <p>Wir haben auf unsere Website Audio- bzw. Videoelemente eingebunden, damit Sie
                                            sich direkt über unsere Website etwa Videos ansehen oder Musik/Podcasts
                                            anhören können. Die Inhalte werden von Dienstanbietern zur Verfügung
                                            gestellt. Alle Inhalte werden also auch von den entsprechenden Servern der
                                            Anbieter bezogen.</p>
                                        <p>Es handelt sich dabei um eingebundene Funktionselemente von Plattformen wie
                                            etwa YouTube, Vimeo oder Spotify. Die Nutzung dieser Portale ist in der
                                            Regel kostenlos, es können aber auch kostenpflichtige Inhalte veröffentlicht
                                            werden. Mit Hilfe dieser eingebundenen Elemente könne Sie sich über unsere
                                            Website die jeweiligen Inhalte anhören oder ansehen.</p>
                                        <p>Wenn Sie Audio- oder Videoelemente auf unsere Website verwenden, können auch
                                            personenbezogene Daten von Ihnen an die Dienstanbieter übermittelt,
                                            verarbeitet und gespeichert werden.</p>
                                        <h3 class="adsimple-312237450">Warum verwenden wir Audio- &amp; Videoelemente
                                            auf unserer Website?</h3>
                                        <p>Natürlich wollen wir Ihnen auf unserer Website das beste Angebot liefern. Und
                                            uns ist bewusst, dass Inhalte nicht mehr bloß in Text und statischem Bild
                                            vermittelt werden. Statt Ihnen einfach nur einen Link zu einem Video zu
                                            geben, bieten wir Ihnen direkt auf unserer Website Audio- und Videoformate,
                                            die unterhaltend oder informativ und im Idealfall sogar beides sind. Das
                                            erweitert unser Service und erleichtert Ihnen den Zugang zu interessanten
                                            Inhalten. Somit bieten wir neben unseren Texten und Bildern auch Video
                                            und/oder Audio-Inhalte an.</p>
                                        <h3 class="adsimple-312237450">Welche Daten werden durch Audio- &amp;
                                            Videoelemente gespeichert?</h3>
                                        <p>Wenn Sie eine Seite auf unserer Website aufrufen, die beispielsweise ein
                                            eingebettetes Video hat, verbindet sich Ihr Server mit dem Server des
                                            Dienstanbieters. Dabei werden auch Daten von Ihnen an den Drittanbieter
                                            übertragen und dort gespeichert. Manche Daten werden ganz unabhängig davon,
                                            ob Sie bei dem Drittanbieter ein Konto haben oder nicht, gesammelt und
                                            gespeichert. Dazu zählen meist Ihre IP-Adresse, Browsertyp, Betriebssystem,
                                            und weitere allgemeine Informationen zu Ihrem Endgerät. Weiters werden von
                                            den meisten Anbietern auch Informationen über Ihre Webaktivität eingeholt.
                                            Dazu zählen etwa Sitzungsdauer, Absprungrate, auf welchen Button Sie
                                            geklickt haben oder über welche Website Sie den Dienst nutzen. All diese
                                            Informationen werden meist über Cookies oder Pixel-Tags (auch Web Beacon
                                            genannt) gespeichert. Pseudonymisierte Daten werden meist in Cookies in
                                            Ihrem Browser gespeichert. Welche Daten genau gespeichert und verarbeitet
                                            werden, erfahren Sie stets in der Datenschutzerklärung des jeweiligen
                                            Anbieters.</p>
                                        <h3 class="adsimple-312237450">Dauer der Datenverarbeitung</h3>
                                        <p>Wie lange die Daten auf den Servern der Drittanbieter genau gespeichert
                                            werden, erfahren Sie entweder weiter unten im Datenschutztext des jeweiligen
                                            Tools oder in der Datenschutzerklärung des Anbieters. Grundsätzlich werden
                                            personenbezogene Daten immer nur so lange verarbeitet, wie es für die
                                            Bereitstellung unserer Dienstleistungen oder Produkte unbedingt nötig wird.
                                            Dies gilt in der Regel auch für Drittanbieter. Meist können Sie davon
                                            ausgehen, dass gewisse Daten über mehrere Jahre auf den Servern der
                                            Drittanbieter gespeichert werden. Daten können speziell in Cookies
                                            unterschiedlich lange gespeichert werden. Manche Cookies werden bereits nach
                                            dem Verlassen der Website wieder gelöscht, anderen können über einige Jahre
                                            in Ihrem Browser gespeichert sein.</p>
                                        <h3 class="adsimple-312237450">Widerspruchsrecht</h3>
                                        <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur
                                            Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert
                                            entweder über unser Cookie-Management-Tool oder über andere
                                            Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch
                                            Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten,
                                            deaktivieren oder löschen. Die Rechtmäßigkeit der Verarbeitung bis zum
                                            Widerruf bleibt unberührt.</p>
                                        <p>Da durch die eingebundenen Audio- und Video-Funktionen auf unserer Seite
                                            meist auch Cookies verwendet werden, sollte Sie sich auch unsere allgemeine
                                            Datenschutzerklärung über Cookies durchlesen. In den Datenschutzerklärungen
                                            der jeweiligen Drittanbieter erfahren Sie genaueres über den Umgang und die
                                            Speicherung Ihrer Daten.</p>
                                        <h3 class="adsimple-312237450">
                                            <strong class="adsimple-312237450">Rechtsgrundlage</strong>
                                        </h3>
                                        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Audio-
                                            und Video-Elemente verarbeitet und gespeichert werden können, gilt diese
                                            Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong
                                                class="adsimple-312237450">(Art. 6 Abs. 1 lit. a DSGVO)</strong>.
                                            Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten
                                            Interesses <strong class="adsimple-312237450">(Art. 6 Abs. 1 lit. f
                                                DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen
                                            oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir
                                            setzen die eingebundenen Audio- und Video-Elemente gleichwohl nur ein,
                                            soweit Sie eine Einwilligung erteilt haben.</p>
                                        <h2 id="soundcloud-datenschutzerklaerung" class="adsimple-312237450">SoundCloud
                                            Datenschutzerklärung</h2>
                                        <table border="1" cellpadding="15">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong class="adsimple-312237450">SoundCloud Datenschutzerklärung
                                                        Zusammenfassung</strong>
                                                    <br/>
                                                    &#x1f465; Betroffene: Besucher der Website<br/>
                                                    &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br/>
                                                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum
                                                    Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse
                                                    können gespeichert werden.<br/>
                                                    Mehr Details dazu finden Sie weiter unten in dieser
                                                    Datenschutzerklärung.<br/>
                                                    &#x1f4c5; Speicherdauer: Daten bleiben grundsätzlich gespeichert,
                                                    solange sie für den Dienstzweck nötig sind<br/>
                                                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                                                    (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <h3 class="adsimple-312237450">Was ist SoundCloud?</h3>
                                        <p>Wir verwenden auf unserer Website Funktionen (Widgets) des Social Media
                                            Netzwerks SoundCloud der Firma SoundCloud Limited, Rheinsberger Str. 76/77,
                                            10115 Berlin, Deutschland. Sie erkennen die Widgets am bekannten orangen
                                            Logo. Durch die Verwendung von Funktionen wie beispielsweise dem Abspielen
                                            von Musik werden Daten an SoundCloud übermittelt, gespeichert und
                                            ausgewertet. In dieser Datenschutzerklärung zeigen wir Ihnen, um welche
                                            Daten es sich handelt, warum wir SoundCloud verwenden und wie Sie Ihre Daten
                                            bzw. die Datenübertragung verwalten oder unterbinden können.</p>
                                        <p>Das Social-Media-Netzwerk SoundCloud ist eine Online-Musikplattform, die dem
                                            Austausch und der Verteilung von Audiodateien dient. Auf SoundCloud bieten
                                            Musiker oder Podcaster ihre Audiodateien zum Downloaden an. Zudem kann man
                                            mit SoundCloud die Audiodateien auch in anderen Websites einbinden. Und
                                            genau das haben auch wir gemacht. Typisch für SoundCloud sind die grafischen
                                            Darstellungen der Audiodateien in Wellenform und die Kommentarleiste. So
                                            können angemeldete User Musikstücke bzw. Podcasts jederzeit anhören und
                                            kommentieren.</p>
                                        <h3 class="adsimple-312237450">Warum verwenden wir SoundCloud auf unserer
                                            Website?</h3>
                                        <p>Unser Ziel ist es, Ihnen auf unserer Webseite den bestmöglichen Service zu
                                            liefern. Damit meinen wir nicht nur unsere Produkte oder Dienstleistungen.
                                            Zu einem gesamtheitlichen Kundenservice zählt auch, wie wohl Sie sich auf
                                            unserer Website fühlen und wie hilfreich unsere Website für Sie ist. Durch
                                            die eingebettete SoundCloud-Abspielfunktion können wir Ihnen akustischen
                                            Content direkt und frei Haus liefern. Sie müssen nicht zuerst irgendeinen
                                            Link folgen, um sich eine Audiodatei anzuhören, sondern können gleich über
                                            unsere Webseite starten.</p>
                                        <h3 class="adsimple-312237450">Welche Daten werden auf SoundCloud
                                            gespeichert?</h3>
                                        <p>Sobald Sie eine unserer Webseiten besuchen, die ein Widget (Like- oder
                                            Share-Button oder Abspielfunktion) eingebaut hat, verbindet sich Ihr Browser
                                            mit einem SoundCloud-Server. Dabei können Daten von Ihnen an SoundCloud
                                            übertragen, dort verwaltet und gespeichert werden. Zum Beispiel erfährt
                                            SoundCloud auf diese Weise Ihre IP-Adresse und welche Seite (in diesem Fall
                                            unsere) Sie wann besucht haben. Wenn Sie ein SoundCloud-Konto haben und
                                            angemeldet sind, während Sie auf unserer Webseite surfen, werden die
                                            erhobenen Daten direkt Ihrem Konto/Account zugewiesen. Das können Sie nur
                                            verhindern, indem Sie sich, während Ihres Aufenthalts auf unserer Website,
                                            von SoundCloud abmelden. Neben den oben genannten Informationen werden in
                                            den Cookies auch Daten über Ihr Userverhalten gespeichert. Wann immer Sie
                                            beispielsweise einen Button klicken, ein Musikstück abspielen oder pausieren
                                            wird diese Information in den Cookies gespeichert. Das Widget bzw.
                                            SoundCloud ist somit in der Lage, Sie zu erkennen und manchmal wird das
                                            Widget auch verwendet, um Ihnen personalisierte Inhalte zu liefern.
                                            SoundCloud verwendet nicht nur eigene Cookies, sondern auch Cookies von
                                            Drittanbietern wie Facebook oder Google Analytics. Diese Cookies dienen dem
                                            Unternehmen mehr Informationen über Ihr Verhalten auf externen Websites und
                                            der eigenen Plattform zu erhalten. Wir als Websitebetreiber bekommen durch
                                            die verwendeten Cookies von SoundCloud keine Informationen über Ihr
                                            Userverhalten. Die Datenübertragung und daher auch die Informationen zu
                                            technischen Geräten und Ihrem Verhalten auf der Webseite findet zwischen
                                            Ihnen und SoundCloud statt.</p>
                                        <p>Im Folgenden zeigen wir Cookies, die gesetzt wurden, wenn man auf eine
                                            Webseite geht, die SoundCloud-Funktionen eingebunden hat. Diese Liste ist
                                            nur ein Beispiel möglicher Cookies und kann keinen Anspruch auf
                                            Vollständigkeit erheben. Bei diesem Beispiel hat der User kein
                                            SoundCloud-Konto:</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name: </strong>sc_anonymous_id<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> 208165-986996-398971-423805312237450-0<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            macht es erst möglich, Dateien oder andere Inhalte in Websites einzubinden
                                            und speichert eine User-ID.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum: </strong>nach 10 Jahren</p>
                                        <p>
                                            <strong class="adsimple-312237450">Anmerkung:<br/>
                                            </strong>Das Cookie sc_anonymous_id wird sofort gesetzt, wenn Sie auf einer
                                            unserer Webseiten sind, die eine Soundcloud-Funktion eingebaut hat. Dafür
                                            müssen Sie mit der Funktion noch nicht interagieren.</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name: </strong>__qca<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> P0-1223379886-1579605792812312237450-7<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            ist ein Drittanbieter-Cookie von Quantcast und sammelt Daten wie
                                            beispielsweise wie oft Sie die Seite besuchen oder wie lange Sie auf der
                                            Seite bleiben. Die gesammelten Informationen werden dann an SoundCloud
                                            weitergegeben.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum: </strong>nach einem Jahr</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> Sclocale<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> de<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Das Cookie
                                            speichert die Spracheinstellung, die Sie voreingestellt haben.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum: </strong>nach einem Jahr</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name: </strong>_soundcloud_session<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> /<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Zu diesem
                                            Cookie konnten wir keine konkreten Informationen in Erfahrung bringen.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum: </strong>nach Sitzungsende
                                        </p>
                                        <p>
                                            <strong class="adsimple-312237450">Name: </strong>_session_auth_key<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> /<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Mit Hilfe des
                                            Cookies können Sitzungsinformationen (also Userverhalten) gespeichert werden
                                            und eine Client-Anfrage authentifiziert werden.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum: </strong>nach 10 Jahren</p>
                                        <p>Zudem verwendet SoundCloud auch weitere Drittanbieter-Cookies wie _fbp, _ga,
                                            gid von Facebook und Google Analytics. All die in den Cookies gespeicherten
                                            Informationen nutzt SoundCloud, um die eigenen Dienste zu verbessern und
                                            personalisierte Werbung auszuspielen.</p>
                                        <h3 class="adsimple-312237450">Wie lange und wo werden die Daten
                                            gespeichert?</h3>
                                        <p>Grundsätzlich bleiben die erhobenen Daten bei SoundCloud gespeichert, solange
                                            ein User-Konto besteht oder es für SoundCloud nötig ist, um die
                                            betriebswirtschaftlichen Ziele zu erreichen. Wie lange genau gespeichert
                                            wird ändert sich abhängig vom Kontext und den rechtlichen Verpflichtungen.
                                            Auch wenn Sie kein Konto haben und personenbezogene Daten gespeichert
                                            wurden, haben Sie das Recht die Datenlöschung zu beantragen.</p>
                                        <h3 class="adsimple-312237450">Wie kann ich meine Daten löschen bzw. die
                                            Datenspeicherung verhindern?</h3>
                                        <p>Wenn Sie ein SoundCloud-Konto haben, können Sie über „Einstellungen“ die
                                            Datenverarbeitung verwalten bzw. Ihr ganzes Konto löschen. Sie können aber
                                            auch Cookies in Ihrem Browser genau nach Ihren Ansprüchen verwalten, löschen
                                            oder deaktivieren. Die Herangehensweise hängt immer von Ihrem verwendeten
                                            Browser ab. Falls Sie sich zum Löschen oder Deaktivieren von Cookies
                                            entscheiden, nehmen Sie bitte zu Kenntnis, dass dann möglicherweise nicht
                                            mehr alle Funktionen verfügbar sind. Unter dem Abschnitt „Cookies“ finden
                                            Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten
                                            Browser.</p>
                                        <h3 class="adsimple-312237450">Rechtsgrundlage</h3>
                                        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
                                            SoundCloud-Elemente verarbeitet und gespeichert werden können, gilt diese
                                            Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong
                                                class="adsimple-312237450">(Art. 6 Abs. 1 lit. a DSGVO)</strong>.
                                            Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten
                                            Interesses <strong class="adsimple-312237450">(Art. 6 Abs. 1 lit. f
                                                DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen
                                            oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir
                                            setzen die eingebundenen SoundCloud-Elemente gleichwohl nur ein, soweit Sie
                                            eine Einwilligung erteilt haben. SoundCloud setzt auch Cookies in Ihrem
                                            Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren
                                            Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung
                                            oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
                                        <p>Wir hoffen, wir haben Ihnen einen guten Überblick über den Datenverkehr durch
                                            SoundCloud geliefert. Wenn Sie mehr über die Datenschutzrichtlinien und dem
                                            allgemeinen Umgang mit Daten durch SoundCloud erfahren wollen, empfehlen wir
                                            Ihnen die Datenschutzerklärung des Unternehmens unter <a
                                                class="adsimple-312237450"
                                                href="https://soundcloud.com/pages/privacy?tid=312237450"
                                                target="_blank" rel="noopener noreferrer">https://soundcloud.com/pages/privacy</a>.
                                        </p>
                                        <h2 id="spotify-datenschutzerklaerung" class="adsimple-312237450">Spotify
                                            Datenschutzerklärung</h2>
                                        <p>Wir verwenden auf unserer Website Spotify, ein Tool für Musik- und Podcasts.
                                            Dienstanbieter ist das schwedische Unternehmen Spotify AB, Regeringsgatan
                                            19, SE-111 53 Stockholm, Schweden. Mehr über die Daten, die durch die
                                            Verwendung von Spotify verarbeitet werden, erfahren Sie in der Privacy
                                            Policy auf <a class="adsimple-312237450"
                                                          href="https://www.spotify.com/de/legal/privacy-policy/">https://www.spotify.com/de/legal/privacy-policy/</a>.
                                        </p>
                                        <h2 id="youtube-datenschutzerklaerung" class="adsimple-312237450">YouTube
                                            Datenschutzerklärung</h2>
                                        <table border="1" cellpadding="15">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <strong class="adsimple-312237450">YouTube Datenschutzerklärung
                                                        Zusammenfassung</strong>
                                                    <br/>
                                                    &#x1f465; Betroffene: Besucher der Website<br/>
                                                    &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br/>
                                                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum
                                                    Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse
                                                    können gespeichert werden.<br/>
                                                    Mehr Details dazu finden Sie weiter unten in dieser
                                                    Datenschutzerklärung.<br/>
                                                    &#x1f4c5; Speicherdauer: Daten bleiben grundsätzlich gespeichert,
                                                    solange sie für den Dienstzweck nötig sind<br/>
                                                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO
                                                    (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <h3 class="adsimple-312237450">Was ist YouTube?</h3>
                                        <p>Wir haben auf unserer Website YouTube-Videos eingebaut. So können wir Ihnen
                                            interessante Videos direkt auf unserer Seite präsentieren. YouTube ist ein
                                            Videoportal, das seit 2006 eine Tochterfirma von Google ist. Betrieben wird
                                            das Videoportal durch YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066,
                                            USA. Wenn Sie auf unserer Website eine Seite aufrufen, die ein YouTube-Video
                                            eingebettet hat, verbindet sich Ihr Browser automatisch mit den Servern von
                                            YouTube bzw. Google. Dabei werden (je nach Einstellungen) verschiedene Daten
                                            übertragen. Für die gesamte Datenverarbeitung im europäischen Raum ist
                                            Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland)
                                            verantwortlich.</p>
                                        <p>Im Folgenden wollen wir Ihnen genauer erklären, welche Daten verarbeitet
                                            werden, warum wir YouTube-Videos eingebunden haben und wie Sie Ihre Daten
                                            verwalten oder löschen können.</p>
                                        <p>Auf YouTube können die User kostenlos Videos ansehen, bewerten, kommentieren
                                            und selbst hochladen. Über die letzten Jahre wurde YouTube zu einem der
                                            wichtigsten Social-Media-Kanäle weltweit. Damit wir Videos auf unserer
                                            Webseite anzeigen können, stellt YouTube einen Codeausschnitt zur Verfügung,
                                            den wir auf unserer Seite eingebaut haben.</p>
                                        <h3 class="adsimple-312237450">Warum verwenden wir YouTube-Videos auf unserer
                                            Website?</h3>
                                        <p>YouTube ist die Videoplattform mit den meisten Besuchern und dem besten
                                            Content. Wir sind bemüht, Ihnen die bestmögliche User-Erfahrung auf unserer
                                            Webseite zu bieten. Und natürlich dürfen interessante Videos dabei nicht
                                            fehlen. Mithilfe unserer eingebetteten Videos stellen wir Ihnen neben
                                            unseren Texten und Bildern weiteren hilfreichen Content zur Verfügung. Zudem
                                            wird unsere Webseite auf der Google-Suchmaschine durch die eingebetteten
                                            Videos leichter gefunden. Auch wenn wir über Google Ads Werbeanzeigen
                                            schalten, kann Google – dank der gesammelten Daten – diese Anzeigen wirklich
                                            nur Menschen zeigen, die sich für unsere Angebote interessieren.</p>
                                        <h3 class="adsimple-312237450">Welche Daten werden von YouTube gespeichert?</h3>
                                        <p>Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video eingebaut hat,
                                            setzt YouTube zumindest ein Cookie, das Ihre IP-Adresse und unsere URL
                                            speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt sind, kann YouTube
                                            Ihre Interaktionen auf unserer Webseite meist mithilfe von Cookies Ihrem
                                            Profil zuordnen. Dazu zählen Daten wie Sitzungsdauer, Absprungrate,
                                            ungefährer Standort, technische Informationen wie Browsertyp,
                                            Bildschirmauflösung oder Ihr Internetanbieter. Weitere Daten können
                                            Kontaktdaten, etwaige Bewertungen, das Teilen von Inhalten über Social Media
                                            oder das Hinzufügen zu Ihren Favoriten auf YouTube sein.</p>
                                        <p>Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto angemeldet
                                            sind, speichert Google Daten mit einer eindeutigen Kennung, die mit Ihrem
                                            Gerät, Browser oder App verknüpft sind. So bleibt beispielsweise Ihre
                                            bevorzugte Spracheinstellung beibehalten. Aber viele Interaktionsdaten
                                            können nicht gespeichert werden, da weniger Cookies gesetzt werden.</p>
                                        <p>In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser
                                            gesetzt wurden. Wir zeigen einerseits Cookies, die ohne angemeldetes
                                            YouTube-Konto gesetzt werden. Andererseits zeigen wir Cookies, die mit
                                            angemeldetem Account gesetzt werden. Die Liste kann keinen
                                            Vollständigkeitsanspruch erheben, weil die Userdaten immer von den
                                            Interaktionen auf YouTube abhängen.</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> YSC<br/>
                                            <strong class="adsimple-312237450">Wert:</strong>
                                            b9-CV6ojI5Y312237450-1<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            registriert eine eindeutige ID, um Statistiken des gesehenen Videos zu
                                            speichern.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach Sitzungsende
                                        </p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> PREF<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> f1=50000000<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            registriert ebenfalls Ihre eindeutige ID. Google bekommt über PREF
                                            Statistiken, wie Sie YouTube-Videos auf unserer Webseite verwenden.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach 8 Monaten</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> GPS<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> 1<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            registriert Ihre eindeutige ID auf mobilen Geräten, um den GPS-Standort zu
                                            tracken.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach 30 Minuten</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> VISITOR_INFO1_LIVE<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> 95Chz8bagyU<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            versucht die Bandbreite des Users auf unseren Webseiten (mit eingebautem
                                            YouTube-Video) zu schätzen.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach 8 Monaten</p>
                                        <p>Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto
                                            angemeldet sind:</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> APISID<br/>
                                            <strong class="adsimple-312237450">Wert:</strong>
                                            zILlvClZSkqGsSwI/AU1aZI6HY7312237450-<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            wird verwendet, um ein Profil über Ihre Interessen zu erstellen. Genützt
                                            werden die Daten für personalisierte Werbeanzeigen.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach 2 Jahren</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> CONSENT<br/>
                                            <strong class="adsimple-312237450">Wert:</strong>
                                            YES+AT.de+20150628-20-0<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Das Cookie
                                            speichert den Status der Zustimmung eines Users zur Nutzung
                                            unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um
                                            User zu überprüfen und Userdaten vor unbefugten Angriffen zu schützen.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum: </strong>nach 19 Jahren</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> HSID<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> AcRwpgUik9Dveht0I<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            wird verwendet, um ein Profil über Ihre Interessen zu erstellen. Diese Daten
                                            helfen personalisierte Werbung anzeigen zu können.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach 2 Jahren</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> LOGIN_INFO<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> AFmmF2swRQIhALl6aL…<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> In diesem
                                            Cookie werden Informationen über Ihre Login-Daten gespeichert.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach 2 Jahren</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> SAPISID<br/>
                                            <strong class="adsimple-312237450">Wert:</strong>
                                            7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            funktioniert, indem es Ihren Browser und Ihr Gerät eindeutig identifiziert.
                                            Es wird verwendet, um ein Profil über Ihre Interessen zu erstellen.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach 2 Jahren</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> SID<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> oQfNKjAsI312237450-<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            speichert Ihre Google-Konto-ID und Ihren letzten Anmeldezeitpunkt in digital
                                            signierter und verschlüsselter Form.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach 2 Jahren</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> SIDCC<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> AN0-TYuqub2JOcDTyL<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            speichert Informationen, wie Sie die Webseite nutzen und welche Werbung Sie
                                            vor dem Besuch auf unserer Seite möglicherweise gesehen haben.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach 3 Monaten</p>
                                        <h3 class="adsimple-312237450">Wie lange und wo werden die Daten
                                            gespeichert?</h3>
                                        <p>Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den
                                            Google-Servern gespeichert. Die meisten dieser Server befinden sich in
                                            Amerika. Unter <a class="adsimple-312237450"
                                                              href="https://www.google.com/about/datacenters/locations/?hl=de"
                                                              target="_blank" rel="follow noopener">https://www.google.com/about/datacenters/locations/?hl=de</a>
                                            sehen Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten sind
                                            auf den Servern verteilt. So sind die Daten schneller abrufbar und vor
                                            Manipulation besser geschützt.</p>
                                        <p>Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten
                                            können Sie jederzeit löschen, andere werden automatisch nach einer
                                            begrenzten Zeit gelöscht und wieder andere werden von Google über längere
                                            Zeit gespeichert. Einige Daten (wie Elemente aus „Meine Aktivität“, Fotos
                                            oder Dokumente, Produkte), die in Ihrem Google-Konto gespeichert sind,
                                            bleiben so lange gespeichert, bis Sie sie löschen. Auch wenn Sie nicht in
                                            einem Google-Konto angemeldet sind, können Sie einige Daten, die mit Ihrem
                                            Gerät, Browser oder App verknüpft sind, löschen.</p>
                                        <h3 class="adsimple-312237450">Wie kann ich meine Daten löschen bzw. die
                                            Datenspeicherung verhindern?</h3>
                                        <p>Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit der 2019
                                            eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten
                                            werden Informationen abhängig von Ihrer Entscheidung &#8211; entweder 3 oder
                                            18 Monate gespeichert und dann gelöscht.</p>
                                        <p>Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren
                                            Browser so konfigurieren, dass Cookies von Google gelöscht bzw. deaktiviert
                                            werden. Je nachdem welchen Browser Sie verwenden, funktioniert dies auf
                                            unterschiedliche Art und Weise. Unter dem Abschnitt „Cookies“ finden Sie die
                                            entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten
                                            Browser.</p>
                                        <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser
                                            so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden
                                            soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es
                                            erlauben oder nicht.</p>
                                        <h3 class="adsimple-312237450">Rechtsgrundlage</h3>
                                        <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
                                            YouTube-Elemente verarbeitet und gespeichert werden können, gilt diese
                                            Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong
                                                class="adsimple-312237450">(Art. 6 Abs. 1 lit. a DSGVO)</strong>.
                                            Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten
                                            Interesses <strong class="adsimple-312237450">(Art. 6 Abs. 1 lit. f
                                                DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen
                                            oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir
                                            setzen die eingebundenen YouTube-Elemente gleichwohl nur ein, soweit Sie
                                            eine Einwilligung erteilt haben. YouTube setzt auch Cookies in Ihrem
                                            Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren
                                            Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung
                                            oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
                                        <p>YouTube verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass
                                            nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes
                                            Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit
                                            verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der
                                            Datenverarbeitung einhergehen.</p>
                                        <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten
                                            (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also
                                            insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet
                                            YouTube von der EU-Kommission genehmigte Standardvertragsklauseln (= Art.
                                            46. Abs. 2 und 3 DSGVO). Diese Klauseln verpflichten YouTube, das
                                            EU-Datenschutzniveau bei der Verarbeitung relevanter Daten auch außerhalb
                                            der EU einzuhalten. Diese Klauseln basieren auf einem Durchführungsbeschluss
                                            der EU-Kommission. Sie finden den Beschluss sowie die Klauseln u.a. hier: <a
                                                class="adsimple-312237450"
                                                href="https://germany.representation.ec.europa.eu/index_de"
                                                target="_blank" rel="follow noopener">https://germany.representation.ec.europa.eu/index_de</a>.
                                        </p>
                                        <p>Da YouTube ein Tochterunternehmen von Google ist, gibt es eine gemeinsame
                                            Datenschutzerklärung. Wenn Sie mehr über den Umgang mit Ihren Daten erfahren
                                            wollen, empfehlen wir Ihnen die Datenschutzerklärung unter <a
                                                class="adsimple-312237450"
                                                href="https://policies.google.com/privacy?hl=de" target="_blank"
                                                rel="noopener noreferrer">https://policies.google.com/privacy?hl=de.</a>
                                        </p>
                                        <h2 id="youtube-abonnieren-button" class="adsimple-312237450">YouTube Abonnieren
                                            Button Datenschutzerklärung</h2>
                                        <p>Wir haben auf unserer Webseite den YouTube Abonnieren Button (engl.
                                            „Subscribe-Button“) eingebaut. Sie erkennen den Button meist am klassischen
                                            YouTube-Logo. Das Logo zeigt vor rotem Hintergrund in weißer Schrift die
                                            Wörter „Abonnieren“ oder „YouTube“ und links davon das weiße „Play-Symbol“.
                                            Der Button kann aber auch in einem anderen Design dargestellt sein.</p>
                                        <p>Unser YouTube-Kanal bietet Ihnen immer wieder lustige, interessante oder
                                            spannende Videos. Mit dem eingebauten „Abonnieren-Button“ können Sie unseren
                                            Kanal direkt von unserer Webseite aus abonnieren und müssen nicht eigens die
                                            YouTube-Webseite aufrufen. Wir wollen Ihnen somit den Zugang zu unserem
                                            umfassenden Content so einfach wie möglich machen. Bitte beachten Sie, dass
                                            YouTube dadurch Daten von Ihnen speichern und verarbeiten kann.</p>
                                        <p>Wenn Sie auf unserer Seite einen eingebauten Abo-Button sehen, setzt YouTube &#8211;
                                            laut Google &#8211; mindestens ein Cookie. Dieses Cookie speichert Ihre
                                            IP-Adresse und unsere URL. Auch Informationen über Ihren Browser, Ihren
                                            ungefähren Standort und Ihre voreingestellte Sprache kann YouTube so
                                            erfahren. Bei unserem Test wurden folgende vier Cookies gesetzt, ohne bei
                                            YouTube angemeldet zu sein:</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> YSC<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> b9-CV6ojI5312237450Y<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            registriert eine eindeutige ID, um Statistiken des gesehenen Videos zu
                                            speichern.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach Sitzungsende
                                        </p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> PREF<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> f1=50000000<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            registriert ebenfalls Ihre eindeutige ID. Google bekommt über PREF
                                            Statistiken, wie Sie YouTube-Videos auf unserer Webseite verwenden.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach 8 Monate</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> GPS<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> 1<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            registriert Ihre eindeutige ID auf mobilen Geräten, um den GPS-Standort zu
                                            tracken.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach 30 Minuten</p>
                                        <p>
                                            <strong class="adsimple-312237450">Name:</strong> VISITOR_INFO1_LIVE<br/>
                                            <strong class="adsimple-312237450">Wert:</strong> 31223745095Chz8bagyU<br/>
                                            <strong class="adsimple-312237450">Verwendungszweck:</strong> Dieses Cookie
                                            versucht die Bandbreite des Users auf unseren Webseiten (mit eingebautem
                                            YouTube-Video) zu schätzen.<br/>
                                            <strong class="adsimple-312237450">Ablaufdatum:</strong> nach 8 Monaten</p>
                                        <p>
                                            <strong class="adsimple-312237450">Anmerkung:</strong> Diese Cookies wurden
                                            nach einem Test gesetzt und können nicht den Anspruch auf Vollständigkeit
                                            erheben.</p>
                                        <p>Wenn Sie in Ihrem YouTube-Konto angemeldet sind, kann YouTube viele Ihrer
                                            Handlungen/Interaktionen auf unserer Webseite mit Hilfe von Cookies
                                            speichern und Ihrem YouTube-Konto zuordnen. YouTube bekommt dadurch zum
                                            Beispiel Informationen wie lange Sie auf unserer Seite surfen, welchen
                                            Browsertyp Sie verwenden, welche Bildschirmauflösung Sie bevorzugen oder
                                            welche Handlungen Sie ausführen.</p>
                                        <p>YouTube verwendet diese Daten zum einen um die eigenen Dienstleistungen und
                                            Angebote zu verbessern, zum anderen um Analysen und Statistiken für
                                            Werbetreibende (die Google Ads verwenden) bereitzustellen.</p>
                                        <p>Alle Texte sind urheberrechtlich geschützt.</p>
                                        <p style="margin-top:15px">Quelle: Erstellt mit dem <a
                                            href="https://www.adsimple.de/datenschutz-generator/"
                                            title="Datenschutz Generator von AdSimple für Deutschland">Datenschutz
                                            Generator</a> von AdSimple</p>
                                    </div>
                                </div>
                            </div>

                        </v-col>
                    </v-row>
                </v-col>
                <Footer/>
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
import TextAnimation from "../components/TextAnimation";
import Footer from "../components/Footer";
//import FileDocumentEdit from 'vue-material-design-icons/FileDocumentEdit.vue'
import NavButton from "@/components/NavButton";

export default {
    name: "Impressum",
    components: {
        NavButton,
        TextAnimation,
        //FileDocumentEdit,
        Footer
    },
    methods: {
        resetCookies() {
            var cookies = document.cookie.split(";");
            document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
            this.$router.push('/')
        }
    }
}
</script>

<style lang="scss" scoped>
.impressum {
    height: 100%;
}

.impressum-responsible {
    line-height: 30px !important;
    padding-bottom: 8px;
}
</style>