<template>
    <div>
        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" style="border-radius: 12px"
                :src=getSoundcloudPlaylistUrl()></iframe>
        <div
            style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
            <a href="https://soundcloud.com/beats-by-amonfire" title="amonfire" target="_blank"
               style="color: #cccccc; text-decoration: none;">amonfire</a> · <a
            href="https://soundcloud.com/beats-by-amonfire/all-about-the-method"
            title="All about the method (Instrumental)" target="_blank" style="color: #cccccc; text-decoration: none;">All
            about the method (Instrumental)</a></div>

    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Soundcloud",
    data: function () {
        return {
            tracklist: [
                {
                    title: 'amonfire - Drum & Bass Playlist',
                    trackid: '1490475856',
                    url: 'sets/drum-bass',
                }
            ],
          playlists: [
                {
                    title: 'amonfire - Drum & Bass Playlist',
                    trackid: '1490475856',
                    url: 'sets/drum-bass',
                }
            ],
            track: {
                title: 'amonfire - Drum & Bass Playlist',
                trackid: '1490475856',
                url: 'sets/drum-bass'
            }
        }
    },
    methods: {
        getRandomSong() {
            let number = Math.floor(Math.random() * this.playlists.length)
            this.track = this.playlists[number]
            console.log(number, this.track)
        },
        getSoundcloudUrl() {
            return "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + this.track.trackid + "&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        },
        getSoundcloudPlaylistUrl() {
            return "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/" + this.track.trackid + "&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        },
        getTrackTitle() {
            return this.track.title
        },
        getTrackId() {
            return this.track.trackid
        },
        getTrackUrl() {
            return "https://soundcloud.com/beats-by-amonfire/" + this.track.url
        }
    },
    mounted() {
        this.getRandomSong()

        let cc = axios.get('https://api.soundcloud.com/tracks/1305253423')
        console.log(cc)
    }
}
</script>
<style scoped lang="scss">
.g-background-default {
    background-color: transparent !important;
}
</style>