<template>
    <div class="text-animation" :upper-text="upperText" :lower-text="lowerText">
        <div class="text-animation-container">
            <h1 class="text-animation-container-text-1 headline-text-shadow">{{ upperText }}</h1>
        </div>
        <div class="text-animation-spacer"></div>
        <div v-if="lowerText" class="text-animation-container">
            <h1 class="text-animation-container-text-2 headline-text-shadow">{{ lowerText }}</h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "TextAnimation",
    props: {
        upperText: String,
        lowerText: String
    }
}
</script>

<style lang="scss">
.text-animation {
    width: fit-content;
    @media (max-width: 768px) {
        margin-left: auto;
        margin-right: auto;
    }

    &-spacer {
        height: 3px;
        background-color: white;
        animation: draw-line .5s forwards;
        box-shadow: 2px 2px 2px 0px black;
    }

    &-container {
        overflow: hidden;

        &-text-1, &-text-2 {
            padding-left: 5px;
            padding-right: 5px;
            text-align: left;
            text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4),
            0px 8px 13px rgba(0, 0, 0, 0.1),
            0px 18px 23px rgba(0, 0, 0, 0.1);

            font-weight: 700;
            @media (max-width: 768px) {
                padding-left: 0;
                text-align: center;
            }
            opacity: 0;
        }

        &-text-1 {
            transform: translateY(50px);
            animation: fade-up 1s .5s forwards;
        }

        &-text-2 {
            transform: translateY(-50px);
            animation: fade-down 1s .5s forwards;
        }
    }
}

@keyframes fade-up {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-down {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes draw-line {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
</style>