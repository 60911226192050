<template>
    <div class="d-flex flex-row flex-wrap justify-space-between sm-bar text--black">
        <a href="mailto:info@amonfire.de" target="_blank" rel="nofollow noopener noreferrer">
            <div class="sm-card" >
                <CalendarIcon :size="iconSize" :fill-color="iconColorFireOrange"/>
                <p class="sm-name button-text-shadow booking-button">Booking</p>
            </div>
        </a>
        <a href="https://soundcloud.com/beats-by-amonfire" target="_blank" rel="nofollow noopener noreferrer">
            <div class="sm-card">
                <SoundcloudIcon :size="iconSize" :fill-color="iconColor"/>
                <p class="sm-name button-text-shadow">Soundcloud</p>
            </div>
        </a>
        <a href="https://open.spotify.com/intl-de/artist/5L9wMWuTya0xMLkdJX1PzY?si=vbpQpqFhT8iMZJTkkeqZKw" target="_blank" rel="nofollow noopener noreferrer">
            <div class="sm-card">
                <SpotifyIcon :size="iconSize" :fill-color="iconColor"/>
                <p class="sm-name button-text-shadow">Spotify</p>
            </div>
        </a>
        <a href="https://www.instagram.com/amon.fire.dnb" target="_blank" rel="nofollow noopener noreferrer">
            <div class="sm-card">
                <InstagramIcon :size="iconSize" :fill-color="iconColor"/>
                <p class="sm-name button-text-shadow">Instagram</p>
            </div>
        </a>
        <a href="https://www.tiktok.com/@amon_fire" target="_blank" rel="nofollow noopener noreferrer">
            <div class="sm-card">
                <TikTokIcon :size="iconSize" :fill-color="iconColor"/>
                <p class="sm-name button-text-shadow pt-2">TikTok</p>
            </div>
        </a>
        <a href="https://www.youtube.com/channel/UCVVX7oKrZ-v3p0ByormMWVw" target="_blank"
           rel="nofollow noopener noreferrer">
            <div class="sm-card">
                <YoutubeIcon :size="iconSize" :fill-color="iconColor"/>
                <p class="sm-name button-text-shadow">Youtube</p>
            </div>
        </a>
    </div>
</template>

<script>
import YoutubeIcon from 'vue-material-design-icons/Youtube.vue'
import InstagramIcon from 'vue-material-design-icons/Instagram.vue'
import SpotifyIcon from 'vue-material-design-icons/Spotify.vue'
import SoundcloudIcon from 'vue-material-design-icons/Soundcloud.vue'
import CalendarIcon from 'vue-material-design-icons/Calendar.vue'
import TikTokIcon from '../assets/icons/TikTokIcon.vue'

export default {
    name: "SocialMediaBar",
    components: {
        InstagramIcon,
        YoutubeIcon,
        TikTokIcon,
        SpotifyIcon,
        SoundcloudIcon,
        CalendarIcon
    },
    data: function () {
        return {
            iconSize: 30,
            iconColor: '#293133',
            iconColorFireOrange: 'var(--fire-orange)'
        }
    },
}
</script>

<style lang="scss" scoped>
.sm-bar {
    a {
        text-decoration: none;
    }

    .sm-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 120px;
        width: 120px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 1);
        margin-top: 20px;
        box-shadow: 0 4px 4px 4px var(--bg-secondary);
        transition: .05s all var(--transition-timing-function);
        @media (max-width: 1024px) {
            height: 80px;
            width: 80px;
            span {
                svg {
                    height: 30px;
                    width: 30px;
                }
            }
            p {
                font-size: 12px;
            }
        }

        &:hover {
            filter: invert(.9);
            transform: translateY(-5px);
            box-shadow: 0 0 0 2px var(--bg-secondary);
        }

        p {
            padding: 0;
            margin: 0;
            color: var(--bg-primary);
        }

        .booking-button {
            color: var(--fire-orange)
        }

    }

    .sm-name {
        font-size: 18px;
        font-weight: bold;
    }
}
</style>
