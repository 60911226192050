<template>
    <v-app>
        <v-main>
<!--          <div class="w-100 h-100 text-center">
          <h1 class="pt-10">Wartungsmodus</h1>
            <p>Aufgrund einer Abmahnwelle bei der Nutzung von Google Fonts ist diese Seite aus Sicherheitsgründen vorrübergehend nicht erreichbar</p>
          </div>-->
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>

export default {
    name: 'App',

    data: () => ({
        //
    }),
};
</script>

<style lang="scss">
@use "styles/styles";
// To not load google fonts from google servers
@import '../node_modules/typeface-roboto/index.css';

@font-face {
    font-family: "Kanit";
    font-display: auto;
    font-style: normal;
    src:    url("./fonts/Kanit/Kanit-ExtraLight.ttf") format("truetype"),
            url("./fonts/Kanit/Kanit-Light.ttf") format("truetype"),
            url("./fonts/Kanit/Kanit-Medium.ttf") format("truetype"),
            url("./fonts/Kanit/Kanit-SemiBold.ttf") format("truetype"),
            url("./fonts/Kanit/Kanit-Bold.ttf") format("truetype"),
            url("./fonts/Kanit/Kanit-ExtraBold.ttf") format("truetype");
}

.v-application {
  font-family: "Kanit", monospace !important;
  .title { // To pin point specific classes of some components
    font-family: "Kanit", monospace !important;
  }
}

body {
    overflow: hidden;
}
</style>
