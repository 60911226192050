<template>
    <v-container class="container--fluid text--white impressum page background-image">
        <v-layout>
            <v-col class="page-container col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 d-flex flex-column container-shadow">
                <NavButton/>
                <v-col class="d-flex flex-column hide-scrollbar px-md-10">
                    <v-row>
                        <v-col class="d-flex flex-column hide-scrollbar px-md-10">
                            <TextAnimation upper-text="Impressum & Datenschutz" lower-text="Angaben gemäß §5 TMG"/>
                            <div class="pt-10">
                                <div class="page-content d-flex flex-row overflow-visible">
                                    <div class="page-content-text d-flex flex-column col-12 container-shadow">
                                        <h2>Inhaber</h2>
                                        <p>Amon Wondra<br/>
                                            Schwartauer Allee 5a<br/>
                                            23554 L&uuml;beck</p>

                                        <h2>Kontakt</h2>
                                        <p>Telefon: &#48;&#49;&#55;&#56;&#56;&#48;&#49;&#51;&#53;&#50;&#57;<br/>
                                            E-Mail: &#105;&#110;&#102;&#111;&#64;&#97;&#109;&#111;&#110;&#102;&#105;&#114;&#101;&#46;&#100;&#101;<br/>
                                            Booking: &#98;&#111;&#111;&#107;&#105;&#110;&#103;&#64;&#97;&#109;&#111;&#110;&#102;&#105;&#114;&#101;&#46;&#100;&#101;<br/>
                                        </p>

                                        <h2 class="impressum-responsible">Redaktionell verantwortlich</h2>
                                        <p>Amon Wondra</p>
                                    </div>
                                </div>
                            </div>

                        </v-col>
                    </v-row>
                </v-col>
                <Footer/>
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
import TextAnimation from "../components/TextAnimation";
import Footer from "../components/Footer";
//import FileDocumentEdit from 'vue-material-design-icons/FileDocumentEdit.vue'
import NavButton from "@/components/NavButton";

export default {
    name: "Impressum",
    components: {
        NavButton,
        TextAnimation,
        //FileDocumentEdit,
        Footer
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.impressum {
    height: 100%;
}

.impressum-responsible {
    line-height: 30px !important;
    padding-bottom: 8px;
}
</style>