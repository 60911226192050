import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)
export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {
        async SEND_MAIL(context, payload) {
            axios({
                method: 'post',
                url: 'https://localhost:8000/mailmon/send/',
                data: payload
            })
                .then(response => {
                    this.info = response.data
                    alert('Nachricht erfolgreich versandt!')
                })
                .catch(() => {
                    alert('Nachricht konnte NICHT versandt werden!')
                })
        }

    },
    modules: {}
})
