<template>
    <v-container class="container--fluid text--white home page background-image">
        <v-layout>
            <v-col class="page-container col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 container-shadow">
                <v-col class="page-content d-flex flex-column hide-scrollbar px-md-10">
                    <div class="d-flex flex-row align-center pt-4 flex-wrap flex-md-nowrap justify-center justify-md-start">
                        <div class="avatar-container">
                            <v-avatar class="avatar content-shadow" size="150">
                                <img :src="require('../assets/amonfire/Coal_2023.jpg')" alt="Bild von mir"
                                    class="avatar-img">
                            </v-avatar>
                            <v-avatar class="logo content-shadow p-5 overflow-visible" color="white" size="150">
                                <img class="avatar-img" src="../assets/logo/AMONFIRE_Logo_Inv.png" alt="Logo">
                            </v-avatar>
                        </div>
                        <div class="page-content-headline ml-md-10" style="position: relative;">
                            <TextAnimation upper-text="amonfire" lower-text="DJ & Produzent" />
                            <!-- <div style="position: absolute; right: 0; top: 0;">
                                <button @click="changeLocale('en')"><v-img width="50" height="50"
                                        :src="require('../assets/icons/united-kingdom.png')"></v-img></button>
                                <button @click="changeLocale('de')"><v-img width="50" height="50"
                                        :src="require('../assets/icons/germany.png')"></v-img></button>
                            </div> -->
                        </div>
                    </div>
                    <SocialMediaBar class="my-5 my-md-15" />
                    <div class="d-flex flex-column mt-10 content-shadow content-element">
                        <h1 class="pb-4 page-content-media-headline headline-text-shadow content-section-headline w-100">
                            Presskit</h1>
                        <!--               <span v-if="this.$i18n.locale === 'en'" style="font-size:12px">The translation is not finished
                            yet</span> -->
                        <div class="page-content-text">
                            <a href="https://songwhip.com/styleztyson/nurmeinmic" target="_blank" ref="noreferrer nofollow"
                                style="display: flex; justify-content: center; align-items: center; text-align: center;">
                                <h2 class="pb-5" style="font-weight: 700;">Latest Release: amonfire, Stylez-Tyson - Nur mein Mic </h2>
                    <!--             <v-img height="250" :src="require('../assets/cover/nurmeinmic_cover_bg.png')">
                                    <h2 class="pb-5" style="font-weight: 700;">Latest Release: amonfire, Stylez-Tyson - Nur mein Mic </h2>
                                </v-img> -->
                            </a>
                            <p>
                                Amon aka. amonfire ist ein aufstrebender Drum and Bass DJ & Produzent aus dem
                                Herzen
                                Lübecks. Seine Musikauswahl reicht von Liquid/Power Liquid Drum & Bass bis hin zu
                                Neurofunk
                                und Ragga Jungle Tracks. Der kreative Mix aus den vocal- und melodienreichen Songs
                                zwischen 170 - 178 BPM lassen nicht nur die Herzen auf der Tanzfläche schneller
                                schlagen, sondern ziehen genrefremde Menschen in den Bann der tanzenden Crowd.
                            </p>
                            <p>
                                Im Frühjahr 2022 führte ihn sein Weg im Rahmen der <a href="https://www.kulturfunke.de/event/unklarbar-newcomer-night-mit-amonfire-dnb-1414" class="in-text-link">Kulturfunke Newcomer Night</a> ins "unklar" - einerkleinen Bar im lübeckerStudenten-Hotspot, der Clemensstraße. Mit seinem ersten Gig legte er dort den Grundsteinseiner DJ Karriere, wodurch er einen Dancefloor nach dem nächsten füllte. Bei demGewölberave der Aftershow Party vom Hansekulturfestival sowie der ersten großen Drum &BassVeranstaltung in Lübeck "M.A.U.S. 01", wo amonfire mit diversen DJs wie Steve Phatvibeaka.DJ Bolt und weiteren lokalen Bekanntheiten auflegte, sorgte er für vibrierende Böden undstrahlende Gesichter. Der große Traum war es fortan auf einem Festival spielen zu dürfenundauch die Anfrage sollte nicht lange auf sich warten lassen. Im August 2022 bekam er dieMöglichkeit in der Glo Lounge auf dem Pangea Festival aufzulegen. Die Resonanz war sogroß,dass das gesamte Wochenende in der Lounge zu Drum & Bass getanzt wurde.,
    
                            </p>
                            <p>
                                Sein erstes Instrumental produzierte amonfire im Juni 2021 an seinem Schreibtisch inseinerWG, welches knapp ein halbes Jahr später als vollständiger Song mit zwei weiterenArtistsseinen Weg auf die gängigen Streaming-Plattformen fand.Darauf folgt im November 2022 der Release seines ersten Drum & Bass Tracks über <a href="https://www.utm-records.de/" rel="noreferrer noopener" target="_blank">UTM-Records.</a>,
                            </p> 
                            <div class="d-flex w-100 flex-row flex-wrap">
                                <v-btn class="promo-mix-btn my-5 my-md-0" href="https://amonfire.de/amonfire.zip"
                                    download="amonfire_presskit.zip">Download Presskit.zip</v-btn>
                                <v-btn href="https://on.soundcloud.com/4RHov" target="_blank"
                                    class="promo-mix-btn ml-sm-5 my-5 my-md-0">ZUM PROMO-MIX</v-btn>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column mt-10 page-content-media content-shadow content-element">
                      <div class="py-5 pl-md-6 content-section-headline headline-text-shadow">
                        <h2>Spotify Playlists</h2>
    
                      </div>
                        <div class="spotify-playlist pa-5">
                            <iframe v-if="pageLoaded && cookieAccepted"
                                    style="border-radius:12px; border:none"
                                    src="https://open.spotify.com/embed/playlist/7ybYcu4ljdq0ZcaKrtGvB3?utm_source=generator"
                                    width="100%"
                                    height="400px"
                                    rel="noreferrer"
                                    frameborder="0" allowtransparency="true" allow="encrypted-media">
                            </iframe>
                            <v-skeleton-loader v-else type="card"></v-skeleton-loader>
                        </div>
                        <div class="spotify-playlist pa-5">
                            <iframe v-if="pageLoaded && cookieAccepted"
                                    style="border-radius:12px; border:none"
                                    src="https://open.spotify.com/embed/playlist/2tZtuaXZRW0VBafGj17N58"
                                    width="100%"
                                    height="400px"
                                    rel="noreferrer"
                                    frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                            <v-skeleton-loader v-else type="card"></v-skeleton-loader>
                        </div>
                      <h2 class="py-5 pl-md-6 content-section-headline headline-text-shadow">Soundcloud Drum & Bass
                        Playlist</h2>
                      <Soundcloud v-if="pageLoaded && cookieAccepted" class="pa-5" />
                      <v-skeleton-loader v-else type="card" title="Eat cookies to stop loading"></v-skeleton-loader>
                        <h2 class="py-5 pl-6 mt-10 content-section-headline headline-text-shadow">Youtube</h2>
                        <div class="mt-4">
                            <YoutubeVideo v-if="pageLoaded && cookieAccepted" headline="amonfire - Mockery"
                                          videoID="eLkx3o8QVgA"
                                          class="pa-5"
                                          :cookie-accepted="cookieAccepted"/>
                            <v-skeleton-loader v-else type="card"></v-skeleton-loader>
                        </div>
                        <div class="mt-10">
                            <YoutubeVideo v-if="pageLoaded && cookieAccepted"
                                          class="pa-5"
                                          headline="Pangea Festival 2022 - Drum & Bass Mix"
                                          videoID="U7ND826MDzM" :cookie-accepted="cookieAccepted"/>
                            <v-skeleton-loader v-else type="card"></v-skeleton-loader>
                        </div>
                        <div class="mt-10">
                            <YoutubeVideo v-if="pageLoaded && cookieAccepted" headline="Habitat Aftermovie"
                                          videoID="56TQEk1Kl-I"
                                          class="pa-5"
                                          :cookie-accepted="cookieAccepted"/>
                            <v-skeleton-loader v-else type="card"></v-skeleton-loader>
                        </div>

                      <div class="d-flex flex-column mt-10 page-content-media content-shadow content-element">
                        <h1 class="pb-4 page-content-media-headline headline-text-shadow content-section-headline">
                          Gallerie</h1>
                        <GallerySlider class="pa-5"/>
                      </div>
                    </div>

                </v-col>
                <Footer/>
            </v-col>
        </v-layout>
        <cookie-law :message="cookieMessage" :button-decline="true"
                    button-class="cookie-law-interaction-button"
                    button-decline-class="cookie-law-interaction-button decline" class="cookie-law-container">
            <div class="cookie-law" slot-scope="props">
                <div class="cookie-law-content">
                    <p class="cookie-law-content-text">
                        {{ cookieMessage }} - <router-link to="/datenschutz">Datenschutz und Cookie Informationen</router-link>
                    </p>
                </div>
                <div class="cookie-law-interaction">
                    <button @click="props.accept"
                            class="btn p-1 mx-md-1 mx-auto cookie-law-interaction-button accept-btn">
                        <span>Akzeptieren</span></button>
                    <button @click="props.close" class="btn p-1 mx-1 cookie-law-interaction-button decline-btn">
                        <span>Ablehnen</span></button>
                </div>
            </div>
        </cookie-law>
    </v-container>
</template>
<script>
/*import Opener from "@/components/Opener";*/
import SocialMediaBar from "@/components/SocialMediaBar";
import YoutubeVideo from "@/components/YoutubeVideo";
import TextAnimation from "@/components/TextAnimation";
import Footer from "../components/Footer";
import CookieLaw from 'vue-cookie-law'
import Soundcloud from "../components/Soundcloud";
import GallerySlider from "../components/GallerySlider";


export default {

    name: 'Home',

    components: {
        Footer,
        TextAnimation,
        SocialMediaBar,
        YoutubeVideo,
        Soundcloud,
        CookieLaw,
        GallerySlider
    },
    data: function () {
        return {
            drawtogetherImg: "../assets/drawtogether.webp",
            navButton: null,
            pageState: true,
            pageLoaded: false,
            cookieAccepted: false,
            cookieMessage: "Diese Seite nutzt nur Cookies 🍪 von externen Medien wie Soundcloud, Youtube und Spotify - Wenn du die Cookies ablehnst, werden diese Inhalte nicht korrekt dargestellt"
        }
    },
    methods: {
        acceptCookieConsent() {
            this.cookieAccepted = true;
        },
        declineCookieConsent() {
            this.cookieAccepted = false;
        },
        initCookieConsent() {
            let acceptBtn = document.querySelector('.accept-btn')
            let declineBtn = document.querySelector('.decline-btn')

            if (acceptBtn && declineBtn) {
                acceptBtn.addEventListener('click', () => {
                    this.acceptCookieConsent()
                })
                declineBtn.addEventListener('click', () => {
                    this.declineCookieConsent()
                })
            } else {
                this.cookieAccepted = (window.localStorage.getItem('cookie:accepted') === 'true') || false
            }


        }
    },
    mounted() {
        this.pageLoaded = true;
        let avatar = document.querySelector('.avatar')
        let logo = document.querySelector('.logo')
        avatar.style.transform = 'rotate3d(0, 1, 0, 180deg)'
        avatar.style.opacity = '0'
        logo.style.transform = 'rotate3d(0, 1, 0, -180deg)'
        logo.style.opacity = '1'

        setTimeout(() => {
            avatar.style.transform = 'rotate3d(0, 1, 0, 0deg)'
            avatar.style.opacity = '1'
            logo.style.transform = 'rotate3d(0, 1, 0, 0deg)'
            logo.style.opacity = '0'
        }, 4000)

        this.initCookieConsent();
    }
}
</script>

<style lang="scss" scoped>
    .promo-mix-btn {
        display: flex;
        flex-wrap: wrap;
        background-color: var(--fire-orange) !important;
        @media screen and (max-width: 768px) {
            margin-left: auto;
            margin-right: auto;
        }
        &:hover {
            background-color: var(--fire-blue) !important;
            color: white;
        }
    }
</style>