import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Kontakt from '../views/Kontakt.vue'
import Impressum from '../views/Impressum.vue'
import Datenschutz from "@/views/Datenschutz";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: Kontakt
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
/*    component: () => import(/!* webpackChunkName: "about" *!/ '../views/Kontakt.vue')*/
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
/*    component: () => import(/!* webpackChunkName: "about" *!/ '../views/Impressum.vue')*/
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz
  }
];

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
});


export default router
