<template>
    <div class="youtube-video" :headline="headline" :videoID="videoID">
        <div class="youtube-video-headline text-center py-2" style="z-index: 1">
            <h3>{{ headline }}</h3>
        </div>
        <div class="youtube-video-video d-flex justify-center black" style="z-index: 0">
            <iframe v-if="cookieAccepted" width="100%" height="315" :src="`https://www.youtube-nocookie.com/embed/${videoID}`"
                    style="border-radius:12px; border:none"
                    title="YouTube video player" frameborder="0"
                    rel="noreferrer noopener"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
            </iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProjectPreview",
    props: {
        videoID: String,
        headline: String,
        projectURL: String,
        cookieAccepted: Boolean
    }
}
</script>

<style lang="scss">
  .youtube-video {
    &-headline {
      background: var(--orange-title-gradient) !important;
    }
  }
</style>
