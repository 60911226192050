<template>
    <div >
        <v-carousel class="gallery-carousel" style="border-radius: 12px">
            <v-carousel-item
                v-for="(image,i) in images"
                :key="i"
                :src="image.src"
            >
            </v-carousel-item>
        </v-carousel>
        <div class="player-container d-flex flex-row mt-5 mb-lg-10 align-center">
            <ChevronLeft class="video-player-icons d-none d-lg-flex ml-10" @click="prevVideo"/>
            <vue-core-video-player class="player-container-video mt-5"
                                   :src="videos[currentVideo]"
                                   :cover="require('../assets/logo/AmonFireLogo-min.png')"
                                   controls="auto"
                                   :autoplay="false"
            >
            </vue-core-video-player>
            <ChevronRight class="video-player-icons d-none d-lg-flex mr-10" @click="nextVideo"/>
            <div class="video-player-mobile-controls d-flex d-lg-none flex-row">
                <ChevronLeft class="video-player-icons d-flex d-lg-none" @click="prevVideo"/>
                <ChevronRight class="video-player-icons d-flex d-lg-none" @click="nextVideo"/>
            </div>
        </div>
    </div>
</template>

<script>

import ChevronLeft from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';

export default {
    name: "GallerySlider",
    components: {
        ChevronLeft,
        ChevronRight
    },
    data() {
        return {
            currentVideo: 0,
            images: [
                {
                    src: require('../assets/amonfire/MAUS_Foto.jpg'),
                    name: "amonfire_profilbild"
                },
                {
                    src: require('../assets/amonfire/Unklar.jpg'),
                    name: "amonfire_profilbild"
                }
            ],
            videos: [
                require('../assets/amonfire/GloLounge2.mp4'),
                require('../assets/amonfire/GloLounge1.mp4'),
                require('../assets/amonfire/GloLounge3.mp4'),
                require('../assets/amonfire/gewoelberave.mp4'),
                require('../assets/amonfire/gewoelberave1.mp4')
            ]
        }
    },
    methods: {
        nextVideo() {
            if (this.currentVideo < this.videos.length - 1) {
                this.currentVideo++
            } else {
                this.currentVideo = 0;
            }
        },
        prevVideo() {
            if (this.currentVideo > 0) {
                this.currentVideo--
            } else {
                this.currentVideo = 0;
            }
        }
    }
}
</script>

<style lang="scss">
.gallery-carousel {
    position: relative;
    z-index: 0;
}

.gallery-carousel a {
    color: white !important;

}

.video-player-mobile-controls {
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
}

.player-container {
    flex-wrap: wrap;
    height: fit-content;
    min-height: 700px;
    position: relative;

    &-video {
        //height: 500px;
        width: 400px !important;
        border: 2px solid var(--bg-primary);
        border-radius: 8px;
        position: relative;
        z-index: 0;
        video {
            border-radius: 8px;
        }
    }

    .video-player-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--bg-primary);
        border-radius: 50%;
        height: 50px;
        width: 50px;
    }

    .video-player-icons:hover {
        cursor: pointer;
    }
}

</style>